import firebase from 'firebase/compat/app'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValue } from '../../../../dashboard/components/PadContext/PadContext'
import { selectMyUserId } from '../../../selectors'
import SyncHandle from '../../../sync_handle'

export function useStartStopNotifications(
  actionType: 'start' | 'stop',
  onlineUsers: Record<string, any>,
  isTranscribing: boolean,
  onChange: (userId: number) => void
) {
  const selfUserId = useSelector(selectMyUserId)
  const isOwner = usePadConfigValue('isOwner')
  const path =
    actionType === 'start' ? 'userTranscriptionStats/startedAt' : 'userTranscriptionStats/stoppedAt'

  // Watch for users starting transcription.
  useEffect(() => {
    let updateWatcher: (snap: firebase.database.DataSnapshot) => void
    let additionWatcher: (snap: firebase.database.DataSnapshot) => void
    if (isOwner && isTranscribing) {
      const cb = (timestamp: number, userId?: string | null) => {
        if (timestamp != null && userId != null) {
          if (
            timestamp !== 0 && // Has started/stopped transcription/recording.
            String(userId) !== selfUserId && // Not the current user.
            onlineUsers[String(userId)] != null // Is online in the pad.
          ) {
            onChange(+userId)
          }
        }
      }
      updateWatcher = SyncHandle().watchChildrenUpdates<number>(path, cb)
      additionWatcher = SyncHandle().watchChildrenAdditions<number>(path, cb)
    }

    return () => {
      if (updateWatcher) {
        SyncHandle().off(path, updateWatcher)
      }
      if (additionWatcher) {
        SyncHandle().off(path, additionWatcher)
      }
    }
  }, [isOwner, selfUserId, isTranscribing, onlineUsers, onChange, path])
}
