import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import { Button, styled, Typography } from '@mui/material'
import React, { useCallback } from 'react'

import { useTranscriberContext } from './TranscriberContext'

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

export function Consent({ isOpen, requestClose }: { isOpen: boolean; requestClose: () => void }) {
  const { acceptTranscription, denyTranscription, askForAudioDevices } = useTranscriberContext()

  const decline = useCallback(() => {
    denyTranscription()
    requestClose()
  }, [denyTranscription, requestClose])

  const accept = useCallback(() => {
    acceptTranscription()
    askForAudioDevices()
    requestClose()
  }, [acceptTranscription, askForAudioDevices, requestClose])

  return (
    <Dialog
      open={isOpen}
      dialogTitle="This interview will be recorded to a text transcript"
      content={
        <div>
          <ContentBlock
            sx={{ display: 'flex', alignItems: 'center', fontSize: '1rem', fontWeight: 600 }}
          >
            <HeadsetMicOutlinedIcon fontSize="small" sx={{ mr: (theme) => theme.spacing(1) }} />{' '}
            Please use headphones for accurate transcription.
          </ContentBlock>
          <ContentBlock variant="body1">
            No audio or video of this call will be saved, only a text transcription of the
            conversation.
          </ContentBlock>
          <ContentBlock variant="body1">
            By clicking "Got it" below, you consent to having your conversation recorded to text.
            Otherwise click "Continue without transcription" to continue without recording.
          </ContentBlock>
        </div>
      }
      actions={[
        <Button key="decline" onClick={decline} variant="outlined" color="inherit">
          Continue without transcription
        </Button>,
        <Button key="accept" onClick={accept} variant="contained" color="primary">
          Got it
        </Button>,
      ]}
    />
  )
}
