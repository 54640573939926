import ZoomVideo from '@zoom/videosdk'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

export const ZoomPreview = () => {
  const zoomContainer = useRef<HTMLVideoElement>(null)
  const { videoDeviceId } = useSelector((state) => state.call)

  useEffect(() => {
    let cleanup = () => {}
    if (videoDeviceId != null) {
      const localVideoTrack = ZoomVideo.createLocalVideoTrack(videoDeviceId)

      if (zoomContainer.current != null) {
        localVideoTrack.start(zoomContainer.current)

        cleanup = () => {
          localVideoTrack?.stop()
        }
      }
    }

    return cleanup
  }, [videoDeviceId])

  return <video style={{ height: '100%' }} ref={zoomContainer} playsInline></video>
}
