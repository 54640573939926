import classNames from 'classnames'
import { clamp } from 'lodash'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import { selectLocalUsername } from 'packs/main/selectors'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useZoomRoomRelay } from './ZoomRoomRelay'

// Specified here to enable dragging, see also videocall.scss
const DEFAULT_POSITION = {
  right: 15,
  bottom: 66,
}
const MIN_MARGIN = 10

interface MinimizedCallProps {
  endCall: () => void
  maximize: () => void
  toggleCC: () => void
  ccEnabled: boolean
}

interface DragPosition {
  left?: number
  right?: number
  top?: number
  bottom?: number
}

const MinimizedCall: FC<MinimizedCallProps> = ({ endCall, maximize, toggleCC, ccEnabled }) => {
  const [position, _setPosition] = useState<DragPosition>(DEFAULT_POSITION)
  const [dragging, setDragging] = useState(false)
  const rootEl = useRef<HTMLDivElement>(null)
  const positionRef = useRef(position)

  const setPosition = (position: DragPosition) => {
    positionRef.current = position
    _setPosition(position)
  }

  let _dragStartPos: DragPosition
  let _dragStartPageX: number
  let _dragStartPageY: number
  let _dragRangeX: number
  let _dragRangeY: number

  useEffect(() => {
    return () => {
      if (dragging) {
        document.removeEventListener('mousemove', handleDrag, {})
        document.removeEventListener('mouseup', handleDragEnd, {})
        document.body.classList.remove('pad--dragging')
      }
    }
  }, [])

  const handleDragStart = (evt: React.MouseEvent) => {
    if (!rootEl.current) {
      return
    }

    // Don't trigger if the mousedown bubbled up from the buttons
    // (end call, minimize, mute, hide video)
    const target = evt.target as HTMLElement
    if (target && (target.tagName === 'BUTTON' || target.parentElement?.tagName === 'BUTTON')) {
      return
    }

    document.addEventListener('mousemove', handleDrag, {})
    document.addEventListener('mouseup', handleDragEnd, {})

    setDragging(true)

    _dragStartPos = positionRef.current
    _dragStartPageX = evt.pageX
    _dragStartPageY = evt.pageY

    // This controls the range of allowed values so you can't create scrollbars
    // by dragging the call off the edge of the screen.
    // Client rects are expensive so just measure this once at drag start
    _dragRangeX = window.innerWidth - rootEl.current.clientWidth
    _dragRangeY = window.innerHeight - rootEl.current.clientHeight
    document.body.classList.add('pad--dragging')
  }

  const handleDrag = (evt: MouseEvent) => {
    const offsetX = evt.pageX - _dragStartPageX
    const offsetY = evt.pageY - _dragStartPageY
    const pos = { ..._dragStartPos }

    if (pos.left !== undefined)
      pos.left = clamp(pos.left + offsetX, MIN_MARGIN, _dragRangeX - MIN_MARGIN)
    else if (pos.right !== undefined)
      pos.right = clamp(pos.right - offsetX, MIN_MARGIN, _dragRangeX - MIN_MARGIN)
    if (pos.top !== undefined)
      pos.top = clamp(pos.top + offsetY, MIN_MARGIN, _dragRangeY - MIN_MARGIN)
    else if (pos.bottom !== undefined)
      pos.bottom = clamp(pos.bottom - offsetY, MIN_MARGIN, _dragRangeY - MIN_MARGIN)

    setPosition(pos)
  }

  const handleDragEnd = () => {
    if (!rootEl.current) {
      return
    }

    document.body.classList.remove('pad--dragging')
    document.removeEventListener('mousemove', handleDrag, {})
    document.removeEventListener('mouseup', handleDragEnd, {})

    const elWidth = rootEl.current.clientWidth
    const elHeight = rootEl.current.clientHeight
    const winWidth = window.innerWidth
    const winHeight = window.innerHeight

    const position = positionRef.current

    // Get the left and top, even if we were specifying position as right and bottom
    // let left = position.left !== undefined ? position.left : winWidth - ((position.right ?? 0) + elWidth)
    let left = position.left ?? winWidth - ((position.right ?? 0) + elWidth)
    let top = position.top ?? winHeight - ((position.bottom ?? 0) + elHeight)

    // Re-compute drag range and clamp the positions again, to avoid the video being
    // partially off-screen in the edge case where someone joined the call while
    // the user was dragging.
    const dragRangeX = winWidth - elWidth
    const dragRangeY = winHeight - elHeight
    left = clamp(left, MIN_MARGIN, dragRangeX - MIN_MARGIN)
    top = clamp(top, MIN_MARGIN, dragRangeY - MIN_MARGIN)

    // Compute right and bottom so we have the full set of positions
    const right = winWidth - (left + elWidth)
    const bottom = winHeight - (top + elHeight)

    // Pin the video call to whichever side is closer (top or bottom, left or right).
    //
    // This way if the video call is in one corner of the screen, and you resize
    // your window, it stays in that corner.
    //
    // Also, when the call expands vertically because someone joined, it'll expand
    // in the right direction (downwards if at the top, upwards if at the bottom).
    const pinnedPosition: DragPosition = {}
    if (left < right) pinnedPosition.left = left
    else pinnedPosition.right = right
    if (top < bottom) pinnedPosition.top = top
    else pinnedPosition.bottom = bottom

    setPosition(pinnedPosition)
    setDragging(false)
  }

  const zoomRelay = useZoomRoomRelay()
  const { slug } = usePadConfigValues('hasTranscriptions', 'slug')
  const { audioDeviceId, videoDeviceId } = useSelector((state) => state.call)
  const username = useSelector(selectLocalUsername)

  return (
    <div
      className={classNames({
        MinimizedCall: true,
        'MinimizedCall--dragging': dragging,
      })}
      ref={rootEl}
      style={position}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
    >
      <div className="MinimizedCall-iconBar">
        <button title="Maximize Call" className="MinimizedCall-maximizeButton" onClick={maximize}>
          <span className="MinimizedCall-buttonGlyph fui-resize" />
        </button>
        <button title="Leave Call" className="MinimizedCall-endCallButton" onClick={endCall}>
          <span className="MinimizedCall-buttonGlyph fui-cross" />
        </button>
      </div>

      <iframe
        key="zoom-room"
        ref={zoomRelay.iframe}
        src={`/${slug}/zoom_room?username=${username}&audioInputDeviceId=${audioDeviceId}&videoDeviceId=${videoDeviceId}&audioOutputDeviceId=default`}
        width="100%"
        height="100%"
        allow="camera; microphone; fullscreen"
        style={{ border: 'none' }}
      />
    </div>
  )
}

export default MinimizedCall
