import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff'
import * as Sentry from '@sentry/browser'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { MessageRelayType } from '../../../zoom_room/MessageRelayContext'
import Modal from '../../modal'
import { selectLocalUsername } from '../../selectors'
import SyncHandle from '../../sync_handle'
import { TwilioCaller } from '../../video/call_root'
import { CC } from '../../video/ClosedCaptions'
import { useZoomRoomRelay } from './ZoomRoomRelay'

interface MaximizedCallProps {
  endCall: () => void
  minimize: () => void
  toggleCC: () => void
  ccEnabled: boolean
  callers: TwilioCaller[]
  muteVideo: () => void
  muteAudio: () => void
  unmuteVideo: () => void
  unmuteAudio: () => void
  videoMuted: boolean
  audioMuted: boolean
  networkQualityLevel: number
}

const MaximizedCall: FC<MaximizedCallProps> = ({
  endCall,
  minimize,
  toggleCC,
  ccEnabled,
  audioMuted,
  videoMuted,
  muteAudio,
  unmuteAudio,
  muteVideo,
  unmuteVideo,
}) => {
  const zoomRelay = useZoomRoomRelay()
  const { hasTranscriptions, slug } = usePadConfigValues('hasTranscriptions', 'slug')
  const { audioDeviceId, videoDeviceId } = useSelector((state) => state.call)
  const username = useSelector(selectLocalUsername)

  const [isConnected, setIsConnected] = useState(false)
  const [isUpdatingAudioMuteStatus, setIsUpdatingAudioMuteStatus] = useState(false)
  const [isUpdatingVideoMuteStatus, setIsUpdatingVideoMuteStatus] = useState(false)

  // listen for a message from the iframe to know when when the zoom room id is ready
  // the zoom room is unable to communicate directly with firebase due to cross-origin restrictions
  useEffect(() => {
    const roomIdListener = (message: MessageEvent) => {
      if (message.data.id) {
        SyncHandle().set('zoomRoomId', message.data.id)
      }
    }
    const removeRoomIdListener = zoomRelay.onMessage(MessageRelayType.Id, roomIdListener)

    const removeAudioMutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfAudioMuted,
      () => {
        setIsUpdatingAudioMuteStatus(false)
        muteAudio()
      }
    )
    const removeAudioUnMutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfAudioUnmuted,
      () => {
        setIsUpdatingAudioMuteStatus(false)
        unmuteAudio()
      }
    )

    const removeVideoMutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfVideoMuted,
      () => {
        setIsUpdatingVideoMuteStatus(false)
        muteVideo()
      }
    )
    const removeVideoUnmutedStatusListener = zoomRelay.onMessage(
      MessageRelayType.SelfVideoUnmuted,
      () => {
        setIsUpdatingVideoMuteStatus(false)
        unmuteVideo()
      }
    )
    const removeConnectedListener = zoomRelay.onMessage(MessageRelayType.Connected, () => {
      setIsConnected(true)
    })
    const removeDisconnectedListener = zoomRelay.onMessage(MessageRelayType.Disconnected, () => {
      setIsConnected(false)
    })

    const errorListener = (message: MessageEvent) => {
      Sentry.captureException(message.data.error, {
        tags: { layer: 'react', feature: 'zoom_room' },
      })
    }
    const removeErrorListener = zoomRelay.onMessage(MessageRelayType.Error, errorListener)

    return () => {
      removeRoomIdListener()
      removeAudioMutedStatusListener()
      removeAudioUnMutedStatusListener()
      removeVideoMutedStatusListener()
      removeVideoUnmutedStatusListener()
      removeConnectedListener()
      removeDisconnectedListener()
      removeErrorListener()
    }
  }, [muteAudio, muteVideo, unmuteAudio, unmuteVideo, zoomRelay])

  const handleAudioMute = useCallback(
    (muted: boolean) => {
      setIsUpdatingAudioMuteStatus(true)
      zoomRelay.sendMessage({
        type: muted ? MessageRelayType.SelfAudioMuted : MessageRelayType.SelfAudioUnmuted,
      })
    },
    [zoomRelay]
  )
  const handleVideoMute = useCallback(
    (muted: boolean) => {
      setIsUpdatingVideoMuteStatus(true)
      zoomRelay.sendMessage({
        type: muted ? MessageRelayType.SelfVideoMuted : MessageRelayType.SelfVideoUnmuted,
      })
    },
    [zoomRelay]
  )

  const footer = (
    <div className="footer">
      <button onClick={minimize} className="minimize-call">
        Minimize
      </button>

      <div className="controls">
        {hasTranscriptions && (
          <button className={`cc ${ccEnabled ? 'enabled' : ''}`} onClick={toggleCC}>
            {ccEnabled ? <ClosedCaptionIcon /> : <ClosedCaptionOffIcon />}
          </button>
        )}

        {isConnected &&
          audioDeviceId != null &&
          (audioMuted ? (
            <button
              className="mute unmute"
              onClick={() => handleAudioMute(false)}
              disabled={isUpdatingAudioMuteStatus}
            >
              Unmute
            </button>
          ) : (
            <button
              className="mute"
              onClick={() => handleAudioMute(true)}
              disabled={isUpdatingAudioMuteStatus}
            >
              Mute
            </button>
          ))}

        {isConnected &&
          videoDeviceId != null &&
          (videoMuted ? (
            <button
              className="hide unhide"
              onClick={() => handleVideoMute(false)}
              disabled={isUpdatingVideoMuteStatus}
            >
              Show Video
            </button>
          ) : (
            <button
              className="hide"
              onClick={() => handleVideoMute(true)}
              disabled={isUpdatingVideoMuteStatus}
            >
              Hide Video
            </button>
          ))}
        <button onClick={endCall} className="end-call">
          Leave Call
        </button>
      </div>
    </div>
  )

  return (
    <Modal padding={0} className="MaximizedCall-modalParent">
      <div className="MaximizedCall">
        {true && (
          <>
            <CC />
            <iframe
              key="zoom-room"
              ref={zoomRelay.iframe}
              src={`/${slug}/zoom_room?username=${username}&audioInputDeviceId=${audioDeviceId}&videoDeviceId=${videoDeviceId}&audioOutputDeviceId=default`}
              width="100%"
              height="100%"
              allow="camera; microphone; fullscreen"
              style={{ border: 'none' }}
              data-testid="zoom-room-iframe"
            />
            {footer}
          </>
        )}
      </div>
    </Modal>
  )
}

export default MaximizedCall
