import { call, put, select, takeEvery } from 'redux-saga/effects'

export default function* zoomSaga() {
  yield takeEvery(
    ['intent_to_start_call_expressed', 'invite_accepted', 'reconnect_to_call_clicked'],
    createTracksForCall
  )
  yield takeEvery('device_switched', switchDevice)
  yield takeEvery('call_join_requested', joinOrCreateCall)
}

function* createTracksForCall() {
  yield call(enumerateDevices)
}

function* enumerateDevices() {
  try {
    const devices = yield call([navigator.mediaDevices, navigator.mediaDevices.enumerateDevices])
    yield put({
      type: 'devices_enumerated',
      devices,
    })
  } catch (err) {
    yield put({ type: 'device_enumerate_failed' })
  }
}

function* switchDevice(action) {
  // Switching to a specific device ID.
  const { kind, deviceId } = action

  yield put({
    type: 'device_switch_succeeded',
    kind,
    deviceId,
  })
}

function* joinOrCreateCall() {
  const [fromInvite, videoState] = yield select((state) => [
    state.call.fromInvite,
    state.call.videoDeviceState,
  ])
  yield put({
    type: 'call_joined',
    _analytics: {
      name: 'Call Joined',
      params: {
        from_invite: fromInvite,
        // other_callers: room.participants.size,
        with_video: videoState === 'open',
      },
    },
  })
}