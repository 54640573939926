export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any
  /** Represents untyped JSON */
  JSON: any
  Upload: any
}

export type AccountPadUsage = {
  __typename?: 'AccountPadUsage'
  accountType: Scalars['String']
  liveStarted: Array<PeriodPadUsageStats>
  name: Scalars['String']
  overages: Array<PeriodPadUsageStats>
  padCreated: Array<PeriodPadUsageStats>
  takeHomeStarted: Array<PeriodPadUsageStats>
  testOverages: Array<PeriodPadUsageStats>
  testSent: Array<PeriodPadUsageStats>
}

/** Appplication type */
export enum Application {
  Interview = 'INTERVIEW',
  Screen = 'SCREEN',
}

/** Authentication flow type */
export enum AuthFlow {
  UserPasswordAuth = 'USER_PASSWORD_AUTH',
  UserSrpAuth = 'USER_SRP_AUTH',
}

/** Valid auto delete periods */
export type AutoDeletePeriod = {
  __typename?: 'AutoDeletePeriod'
  displayValue: Scalars['String']
  name?: Maybe<Scalars['String']>
}

/** Valid auto delete periods */
export enum AutoDeletePeriods {
  /** months_12 */
  Months_12 = 'Months_12',
  /** months_18 */
  Months_18 = 'Months_18',
  /** months_24 */
  Months_24 = 'Months_24',
  /** months_3 */
  Months_3 = 'Months_3',
  /** months_36 */
  Months_36 = 'Months_36',
  /** months_6 */
  Months_6 = 'Months_6',
}

/** Valid auto end periods */
export type AutoEndPeriod = {
  __typename?: 'AutoEndPeriod'
  displayValue: Scalars['String']
  name?: Maybe<Scalars['String']>
}

/** Valid auto end periods */
export enum AutoEndPeriods {
  /** 14 days */
  Days_14 = 'Days_14',
  /** 30 days */
  Days_30 = 'Days_30',
  /** 60 days */
  Days_60 = 'Days_60',
  /** 7 days */
  Days_7 = 'Days_7',
}

/** Autogenerated input type of AutocreateUserGlobal */
export type AutocreateUserGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  codingameCompanyUsers: Scalars['String']
  codingameUser: Scalars['String']
  cognitoUsername: Scalars['String']
  organizationGlobalId: Scalars['String']
  startFreeTrial?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of AutocreateUserGlobal */
export type AutocreateUserGlobalPayload = {
  __typename?: 'AutocreateUserGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
}

/** Properties of Billable */
export type Billable = Organization | User

/** Business address fields */
export type BusinessAddress = {
  __typename?: 'BusinessAddress'
  businessName?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  cityAndRegion?: Maybe<Scalars['String']>
  contactName?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  cancellationReasonAttributes: CancellationReasonAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of CancelSubscription */
export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message: Scalars['String']
  quota: Quota
}

/** Cancellation reason */
export enum CancellationReason {
  AsACandidateCompletedPreparing = 'AsACandidateCompletedPreparing',
  HadIssues = 'HadIssues',
  IAmNotReadyToCommit = 'IAmNotReadyToCommit',
  MissingFeature = 'MissingFeature',
  NoLongerHiring = 'NoLongerHiring',
  OtherReasonNotSpecified = 'OtherReasonNotSpecified',
  PriceIncrease = 'PriceIncrease',
  SwitchingProvider = 'SwitchingProvider',
  TooExpensive = 'TooExpensive',
}

/** Attributes for a cancellation reason */
export type CancellationReasonAttributes = {
  reason: CancellationReason
  reasonDetails?: Maybe<Scalars['JSON']>
  tellUsMore?: Maybe<Scalars['String']>
}

export type CandidateInstructions = {
  __typename?: 'CandidateInstructions'
  defaultVisible?: Maybe<Scalars['Boolean']>
  instructions?: Maybe<Scalars['String']>
}

export type CandidateInstructionsUpdate = {
  defaultVisible: Scalars['Boolean']
  instructions: Scalars['String']
}

/** Code error type */
export enum CodeError {
  AutocreateFailedError = 'AUTOCREATE_FAILED_ERROR',
  CognitoUsernameAlreadyExistsError = 'COGNITO_USERNAME_ALREADY_EXISTS_ERROR',
  CognitoUsernameAlreadyExistsWithDifferentEmailError = 'COGNITO_USERNAME_ALREADY_EXISTS_WITH_DIFFERENT_EMAIL_ERROR',
  CognitoUserPoolNotFound = 'COGNITO_USER_POOL_NOT_FOUND',
  CreditableAlreadyCreditedError = 'CREDITABLE_ALREADY_CREDITED_ERROR',
  CreditableOutsideCurrentBillingCycleError = 'CREDITABLE_OUTSIDE_CURRENT_BILLING_CYCLE_ERROR',
  EmailVerificationMismatch = 'EMAIL_VERIFICATION_MISMATCH',
  InvalidArguments = 'INVALID_ARGUMENTS',
  OrganizationDoesNotExistError = 'ORGANIZATION_DOES_NOT_EXIST_ERROR',
  QuotaExceededError = 'QUOTA_EXCEEDED_ERROR',
  RecordInvalidError = 'RECORD_INVALID_ERROR',
  RecordNotFoundError = 'RECORD_NOT_FOUND_ERROR',
  RecordNotUniqueError = 'RECORD_NOT_UNIQUE_ERROR',
  SharedCreditsDisabledError = 'SHARED_CREDITS_DISABLED_ERROR',
  UserNotFound = 'USER_NOT_FOUND',
  UserNotFoundInCognito = 'USER_NOT_FOUND_IN_COGNITO',
  UserWithAnotherUsername = 'USER_WITH_ANOTHER_USERNAME',
}

export type CognitoLoginMethod = {
  __typename?: 'CognitoLoginMethod'
  authFlowType: AuthFlow
  canUseCognitoLogin: Scalars['Boolean']
  cognitoUserPool?: Maybe<CognitoUserPool>
  cognitoUsername?: Maybe<Scalars['String']>
  migratedOnCognito: Scalars['Boolean']
}

export type CognitoSsoSamlConfig = {
  __typename?: 'CognitoSsoSamlConfig'
  cognitoClientId?: Maybe<Scalars['String']>
  cognitoIdentityProviderName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  ssoEnforced?: Maybe<Scalars['Boolean']>
}

export type CognitoUserPool = {
  __typename?: 'CognitoUserPool'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  region: Scalars['String']
  userPoolId: Scalars['String']
  userPoolWebClientId: Scalars['String']
}

/** Autogenerated input type of ConfirmQuotaGlobal */
export type ConfirmQuotaGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  consumables: Array<Consumable>
  organizationIdGlobal: Scalars['String']
}

/** Autogenerated return type of ConfirmQuotaGlobal */
export type ConfirmQuotaGlobalPayload = {
  __typename?: 'ConfirmQuotaGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  quota?: Maybe<Quota>
}

export type Consumable = {
  id: Scalars['String']
  type: ConsumableName
}

/** Consumable type */
export enum ConsumableName {
  ScreenTest = 'SCREEN_TEST',
}

/** Autogenerated input type of ConsumeQuotaGlobal */
export type ConsumeQuotaGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  consumables: Array<Consumable>
  organizationIdGlobal: Scalars['String']
}

/** Autogenerated return type of ConsumeQuotaGlobal */
export type ConsumeQuotaGlobalPayload = {
  __typename?: 'ConsumeQuotaGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  quota?: Maybe<Quota>
}

/** Autogenerated input type of CreateCustomDatabase */
export type CreateCustomDatabaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customDatabaseAttributes: CustomDatabaseCreateAttributes
}

/** Autogenerated return type of CreateCustomDatabase */
export type CreateCustomDatabasePayload = {
  __typename?: 'CreateCustomDatabasePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customDatabase?: Maybe<CustomDatabase>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CreateCustomFile */
export type CreateCustomFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customFileAttributes: CustomFileCreateAttributes
}

/** Autogenerated return type of CreateCustomFile */
export type CreateCustomFilePayload = {
  __typename?: 'CreateCustomFilePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customFile?: Maybe<CustomFile>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CreateOrganizationGlobal */
export type CreateOrganizationGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  mainUser: GlobalMainUser
  organization: GlobalOrganization
  otherUsers: Array<GlobalOtherUsers>
}

/** Autogenerated return type of CreateOrganizationGlobal */
export type CreateOrganizationGlobalPayload = {
  __typename?: 'CreateOrganizationGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  mainUser?: Maybe<User>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of CreateOrganization */
export type CreateOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  organizationCreateAttributes: OrganizationCreateAttributes
}

/** Autogenerated return type of CreateOrganization */
export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of CreatePad */
export type CreatePadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  padCreateAttributes?: Maybe<PadCreateAttributes>
}

/** Autogenerated return type of CreatePad */
export type CreatePadPayload = {
  __typename?: 'CreatePadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  pad?: Maybe<Pad>
}

/** Autogenerated input type of CreateQuestionFromEnvironment */
export type CreateQuestionFromEnvironmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  environmentSlug: Scalars['String']
  padSlug: Scalars['String']
  saveDrawing?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

/** Autogenerated return type of CreateQuestionFromEnvironment */
export type CreateQuestionFromEnvironmentPayload = {
  __typename?: 'CreateQuestionFromEnvironmentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  question?: Maybe<Question>
}

/** Autogenerated input type of CreateQuestion */
export type CreateQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  questionAttributes: QuestionCreateAttributes
}

/** Autogenerated return type of CreateQuestion */
export type CreateQuestionPayload = {
  __typename?: 'CreateQuestionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  question?: Maybe<Question>
}

/** Autogenerated input type of CreateQuickAction */
export type CreateQuickActionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  quickActionCreateAttributes: QuickActionCreateAttributes
}

/** Autogenerated return type of CreateQuickAction */
export type CreateQuickActionPayload = {
  __typename?: 'CreateQuickActionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  quickAction?: Maybe<QuickAction>
}

/** Autogenerated input type of CreditQuotaGlobal */
export type CreditQuotaGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  creditables: Array<Creditable>
  organizationIdGlobal: Scalars['String']
}

/** Autogenerated return type of CreditQuotaGlobal */
export type CreditQuotaGlobalPayload = {
  __typename?: 'CreditQuotaGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  quota?: Maybe<Quota>
}

export type Creditable = {
  id: Scalars['String']
  type: CreditableName
}

/** Creditable type */
export enum CreditableName {
  ScreenTest = 'SCREEN_TEST',
}

export type CrendentialValidity = {
  __typename?: 'CrendentialValidity'
  emailVerified?: Maybe<Scalars['Boolean']>
  validCredentials: Scalars['Boolean']
}

/** Valid currency codes */
export enum Currency {
  Default = 'DEFAULT',
  Eur = 'EUR',
  Usd = 'USD',
}

export type CustomDatabase = {
  __typename?: 'CustomDatabase'
  attachedQuestionCount: Scalars['Int']
  createdAt: Scalars['ISO8601DateTime']
  description?: Maybe<Scalars['String']>
  global: Scalars['Boolean']
  id: Scalars['Int']
  language: Scalars['String']
  output?: Maybe<Scalars['String']>
  questions: Array<Question>
  schema?: Maybe<Scalars['String']>
  schemaJson?: Maybe<Scalars['JSON']>
  schemaTablePositions?: Maybe<Scalars['JSON']>
  script: Scalars['String']
  state: Scalars['String']
  title?: Maybe<Scalars['String']>
}

/** Attributes for creating custom database */
export type CustomDatabaseCreateAttributes = {
  description?: Maybe<Scalars['String']>
  language: Scalars['String']
  script: Scalars['String']
  title?: Maybe<Scalars['String']>
}

/** Attributes for updating a custom database */
export type CustomDatabaseUpdateAttributes = {
  description?: Maybe<Scalars['String']>
  id: Scalars['Int']
  language?: Maybe<Scalars['String']>
  schemaTablePositions?: Maybe<Scalars['JSON']>
  script?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type CustomDatabasesSearchResults = {
  __typename?: 'CustomDatabasesSearchResults'
  pagesTotal: Scalars['Int']
  records: Array<CustomDatabase>
  totalRecords: Scalars['Int']
}

/** Sort options for Custom Databases */
export enum CustomDatabasesSort {
  AttachedAsc = 'attachedAsc',
  AttachedDesc = 'attachedDesc',
  CreatedAtAsc = 'createdAtAsc',
  CreatedAtDesc = 'createdAtDesc',
  LanguageAsc = 'languageAsc',
  LanguageDesc = 'languageDesc',
  StatusAsc = 'statusAsc',
  StatusDesc = 'statusDesc',
  TitleAsc = 'titleAsc',
  TitleDesc = 'titleDesc',
}

export type CustomFile = {
  __typename?: 'CustomFile'
  attachedQuestionCount: Scalars['Int']
  createdAt: Scalars['ISO8601DateTime']
  createdTimeAgo?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  filename?: Maybe<Scalars['String']>
  filesize?: Maybe<Scalars['String']>
  global: Scalars['Boolean']
  id: Scalars['String']
  mimeType?: Maybe<Scalars['String']>
  questions: Array<Question>
  rawFilesize: Scalars['Int']
  title: Scalars['String']
  userId: Scalars['Int']
}

/** Attributes for creating custom file */
export type CustomFileCreateAttributes = {
  description?: Maybe<Scalars['String']>
  file: Scalars['Upload']
  title: Scalars['String']
}

/** Sort options for Custom Files */
export enum CustomFileSort {
  CreatedAtAsc = 'createdAtAsc',
  CreatedAtDesc = 'createdAtDesc',
  FileAsc = 'fileAsc',
  FileDesc = 'fileDesc',
  SizeAsc = 'sizeAsc',
  SizeDesc = 'sizeDesc',
  TitleAsc = 'titleAsc',
  TitleDesc = 'titleDesc',
}

/** Attributes for updating custom file */
export type CustomFileUpdateAttributes = {
  description?: Maybe<Scalars['String']>
  file?: Maybe<Scalars['Upload']>
  id: Scalars['String']
  title?: Maybe<Scalars['String']>
}

export type CustomFilesSearchResults = {
  __typename?: 'CustomFilesSearchResults'
  records: Array<CustomFile>
  totalRecords: Scalars['Int']
}

/** Autogenerated input type of DeleteAccount */
export type DeleteAccountInput = {
  cancellationReasonAttributes: CancellationReasonAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of DeleteAccount */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message: Scalars['String']
  quota: Quota
}

/** Autogenerated input type of DeleteCreditCard */
export type DeleteCreditCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of DeleteCreditCard */
export type DeleteCreditCardPayload = {
  __typename?: 'DeleteCreditCardPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message: Scalars['String']
  quota: Quota
}

/** Autogenerated input type of DeleteCustomDatabase */
export type DeleteCustomDatabaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

/** Autogenerated return type of DeleteCustomDatabase */
export type DeleteCustomDatabasePayload = {
  __typename?: 'DeleteCustomDatabasePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customDatabase?: Maybe<CustomDatabase>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of DeleteCustomFile */
export type DeleteCustomFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['String']
}

/** Autogenerated return type of DeleteCustomFile */
export type DeleteCustomFilePayload = {
  __typename?: 'DeleteCustomFilePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customFile?: Maybe<CustomFile>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of DeleteInvite */
export type DeleteInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

/** Autogenerated return type of DeleteInvite */
export type DeleteInvitePayload = {
  __typename?: 'DeleteInvitePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  invite?: Maybe<Invite>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of DeleteQuestion */
export type DeleteQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

/** Autogenerated return type of DeleteQuestion */
export type DeleteQuestionPayload = {
  __typename?: 'DeleteQuestionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  question?: Maybe<Question>
}

/** Autogenerated input type of DeleteQuickAction */
export type DeleteQuickActionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  quickActionDeleteAttributes: QuickActionDeleteAttributes
}

/** Autogenerated return type of DeleteQuickAction */
export type DeleteQuickActionPayload = {
  __typename?: 'DeleteQuickActionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  quickAction?: Maybe<QuickAction>
}

export type Drawing = {
  __typename?: 'Drawing'
  boardId: Scalars['String']
  id: Scalars['Int']
}

export type DrawingAttributes = {
  boardId: Scalars['String']
  id?: Maybe<Scalars['Int']>
}

/** Email domain attached to an organization */
export type EmailDomain = {
  __typename?: 'EmailDomain'
  domain: Scalars['String']
  organization: Organization
}

/** A user-readable validation error */
export type Error = {
  __typename?: 'Error'
  /** additional useful information, like an error code */
  extensions?: Maybe<ErrorExtensions>
  formStep?: Maybe<QuestionWizardStep>
  /** A description of the error */
  message: Scalars['String']
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']>>
}

/** Extensions for a user-readable validation error to provide client with more context */
export type ErrorExtensions = {
  __typename?: 'ErrorExtensions'
  /** for specific code error types */
  code?: Maybe<CodeError>
  /** for array inputs, specifies which element the error refers to */
  index?: Maybe<Scalars['Int']>
  /** which input object the error is attributed to */
  inputObjectType?: Maybe<Scalars['String']>
}

export type ExampleQuestion = {
  __typename?: 'ExampleQuestion'
  createdAt: Scalars['ISO8601DateTime']
  description?: Maybe<Scalars['String']>
  difficulty?: Maybe<QuestionDifficulty>
  id: Scalars['String']
  languageDisplays?: Maybe<Array<Scalars['String']>>
  languages?: Maybe<Array<Scalars['String']>>
  padType: QuestionPadTypes
  projectTemplate?: Maybe<ProjectTemplate>
  questionVariants: Array<QuestionVariant>
  source?: Maybe<Scalars['String']>
  tags: Array<GlobalQuestionTag>
  testCasesEnabled: Scalars['Boolean']
  title: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
  variations: Array<ExampleQuestionVariation>
}

/** Sort options for Example Question */
export enum ExampleQuestionSort {
  SortPrecedenceDescThenTitleAsc = 'sortPrecedenceDescThenTitleAsc',
  TitleAsc = 'titleAsc',
  TitleDesc = 'titleDesc',
}

export type ExampleQuestionVariation = {
  __typename?: 'ExampleQuestionVariation'
  candidateInstructions?: Maybe<Array<CandidateInstructions>>
  contents?: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  customDatabase?: Maybe<CustomDatabase>
  customFiles?: Maybe<Array<CustomFile>>
  description?: Maybe<Scalars['String']>
  fileContents?: Maybe<Scalars['JSON']>
  id: Scalars['String']
  language?: Maybe<Scalars['String']>
  padType: QuestionPadTypes
  parameters?: Maybe<Array<QuestionParameter>>
  projectTemplate?: Maybe<ProjectTemplate>
  questionVariants: Array<QuestionVariant>
  returnValueDataType?: Maybe<TestCaseDataTypes>
  solution?: Maybe<Scalars['String']>
  testCases?: Maybe<Array<TestCase>>
  testCasesEnabled?: Maybe<Scalars['Boolean']>
  title: Scalars['String']
  updatedAt: Scalars['ISO8601DateTime']
}

export type ExampleQuestionsSearchResults = {
  __typename?: 'ExampleQuestionsSearchResults'
  pageNumber: Scalars['Int']
  pagesTotal: Scalars['Int']
  records: Array<ExampleQuestion>
  totalRecords: Scalars['Int']
}

/** Autogenerated input type of ExtendTrial */
export type ExtendTrialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of ExtendTrial */
export type ExtendTrialPayload = {
  __typename?: 'ExtendTrialPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message: Scalars['String']
}

/** Autogenerated input type of FavoriteQuestion */
export type FavoriteQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
  remove?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of FavoriteQuestion */
export type FavoriteQuestionPayload = {
  __typename?: 'FavoriteQuestionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  question?: Maybe<Question>
}

/** Autogenerated input type of ForkPad */
export type ForkPadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  padForkAttributes: PadForkAttributes
}

/** Autogenerated return type of ForkPad */
export type ForkPadPayload = {
  __typename?: 'ForkPadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  pad?: Maybe<Pad>
}

export type GlobalMainUser = {
  email: Scalars['String']
  fullName: Scalars['String']
  personaRole: UserPersonaRole
}

export type GlobalOrganization = {
  defaultLanguageId: Scalars['String']
  firstProduct: Application
  name: Scalars['String']
  planId: Scalars['Int']
  screenUsersPage: Scalars['Boolean']
  trialEndDate: Scalars['String']
}

export type GlobalOtherUsers = {
  email: Scalars['String']
  role: InviteRole
}

export type GlobalQuestionTag = {
  __typename?: 'GlobalQuestionTag'
  id: Scalars['Int']
  tag: Scalars['String']
}

/** Valid hubspot form ids */
export enum HubspotFormId {
  AccountDeletionRequest = 'accountDeletionRequest',
  ApiKeyRequest = 'apiKeyRequest',
  CancellationRequest = 'cancellationRequest',
  HowDidYouHearAboutUs = 'howDidYouHearAboutUs',
}

/** Attributes for submitting a hubspot form */
export type HubspotFormSubmitAttributes = {
  fields: Scalars['JSON']
  formId: HubspotFormId
}

/** Autogenerated input type of HubspotFormSubmit */
export type HubspotFormSubmitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  hubspotFormSubmitAttributes: HubspotFormSubmitAttributes
}

/** Autogenerated return type of HubspotFormSubmit */
export type HubspotFormSubmitPayload = {
  __typename?: 'HubspotFormSubmitPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

export type Invite = {
  __typename?: 'Invite'
  createdAt: Scalars['ISO8601DateTime']
  email: Scalars['String']
  globalId: Scalars['String']
  hasRecipient: Scalars['Boolean']
  id: Scalars['Int']
  organization: Organization
  organizationGlobalId: Scalars['String']
  recipientCognitoUsername?: Maybe<Scalars['String']>
  recipientId?: Maybe<Scalars['Int']>
  role?: Maybe<InviteRole>
  sender?: Maybe<User>
  senderCognitoUsername?: Maybe<Scalars['String']>
}

/** Attributes to create an invite */
export type InviteCreateAttributes = {
  email: Scalars['String']
  organizationId?: Maybe<Scalars['Int']>
  role: InviteRole
}

/** Role options for Invites */
export enum InviteRole {
  Admin = 'admin',
  Member = 'member',
}

export type Invoice = {
  __typename?: 'Invoice'
  amountDue: Scalars['Int']
  amountDueDisplayable: Scalars['String']
  chargeSource: InvoiceChargeSource
  discountCouponAmountOff?: Maybe<Scalars['Float']>
  discountCouponAmountOffDisplayable?: Maybe<Scalars['String']>
  discountCouponPercentOff?: Maybe<Scalars['Float']>
  discountCouponPercentOffDisplayable?: Maybe<Scalars['String']>
  id: Scalars['String']
  invoiceDate: Scalars['String']
  invoiceNumber: Scalars['Int']
  lines: Array<InvoiceLine>
  paymentStatus: Scalars['String']
  priorInvoice?: Maybe<Invoice>
  quota: Quota
  subtotalDisplayable: Scalars['String']
}

export type InvoiceChargeSource = {
  __typename?: 'InvoiceChargeSource'
  brand: Scalars['String']
  last4: Scalars['String']
  name: Scalars['String']
}

export type InvoiceLine = {
  __typename?: 'InvoiceLine'
  amountDisplayable: Scalars['String']
  description?: Maybe<Scalars['String']>
}

/** Autogenerated input type of JoinOrganization */
export type JoinOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  organizationId: Scalars['Int']
}

/** Autogenerated return type of JoinOrganization */
export type JoinOrganizationPayload = {
  __typename?: 'JoinOrganizationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  user: User
}

export type JoinableOrganizationDomain = {
  __typename?: 'JoinableOrganizationDomain'
  domain: Scalars['String']
  enabled: Scalars['Boolean']
  id?: Maybe<Scalars['Int']>
}

/** Attributes for joinable organization domain when used as part of updating an Organization. */
export type JoinableOrganizationDomainUpdateAttributes = {
  domain?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
}

/** A supported programming language */
export type Language = {
  __typename?: 'Language'
  category: Scalars['String']
  customFileAllowed: Scalars['Boolean']
  databaseAllowed: Scalars['Boolean']
  example?: Maybe<Scalars['String']>
  id: Scalars['String']
  isDatabaseLanguage: Scalars['Boolean']
  languageClientId?: Maybe<Scalars['String']>
  name: Scalars['String']
  testCaseGrading: Scalars['Boolean']
  version?: Maybe<Scalars['String']>
}

/** Languages */
export enum LanguageKey {
  Bash = 'bash',
  C = 'c',
  Clojure = 'clojure',
  Coffeescript = 'coffeescript',
  Cpp = 'cpp',
  Csharp = 'csharp',
  Dart = 'dart',
  Elixir = 'elixir',
  Erlang = 'erlang',
  Fsharp = 'fsharp',
  Go = 'go',
  Gsheets = 'gsheets',
  Haskell = 'haskell',
  Html = 'html',
  Java = 'java',
  Javascript = 'javascript',
  Julia = 'julia',
  Kotlin = 'kotlin',
  Lua = 'lua',
  Markdown = 'markdown',
  Mysql = 'mysql',
  Objc = 'objc',
  Ocaml = 'ocaml',
  Perl = 'perl',
  Php = 'php',
  Plaintext = 'plaintext',
  Postgresql = 'postgresql',
  Python = 'python',
  Python3 = 'python3',
  R = 'r',
  Ruby = 'ruby',
  Rust = 'rust',
  Scala = 'scala',
  Solidity = 'solidity',
  Swift = 'swift',
  Tcl = 'tcl',
  Typescript = 'typescript',
  Vb = 'vb',
  Verilog = 'verilog',
}

export type LeverStageOption = {
  __typename?: 'LeverStageOption'
  id: Scalars['String']
  text: Scalars['String']
}

export type LoginMethod = {
  __typename?: 'LoginMethod'
  cognitoLoginInfo?: Maybe<CognitoLoginMethod>
  isCodingameUserOnly: Scalars['Boolean']
  screenSsoLoginInfo?: Maybe<ScreenSsoLoginMethod>
  ssoLoginInfo?: Maybe<SsoLoginMethod>
}

/** Autogenerated input type of MigrateUserToCognitoGlobal */
export type MigrateUserToCognitoGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  cognitoUserPoolId?: Maybe<Scalars['String']>
  cognitoUsername?: Maybe<Scalars['String']>
  email: Scalars['String']
}

/** Autogenerated return type of MigrateUserToCognitoGlobal */
export type MigrateUserToCognitoGlobalPayload = {
  __typename?: 'MigrateUserToCognitoGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
}

export type Mutation = {
  __typename?: 'Mutation'
  autocreateUserGlobal?: Maybe<AutocreateUserGlobalPayload>
  cancelSubscription?: Maybe<CancelSubscriptionPayload>
  confirmQuotaGlobal?: Maybe<ConfirmQuotaGlobalPayload>
  consumeQuotaGlobal?: Maybe<ConsumeQuotaGlobalPayload>
  createCustomDatabase?: Maybe<CreateCustomDatabasePayload>
  createCustomFile?: Maybe<CreateCustomFilePayload>
  createOrganization?: Maybe<CreateOrganizationPayload>
  createOrganizationGlobal?: Maybe<CreateOrganizationGlobalPayload>
  createPad?: Maybe<CreatePadPayload>
  createQuestion?: Maybe<CreateQuestionPayload>
  createQuestionFromEnvironment?: Maybe<CreateQuestionFromEnvironmentPayload>
  createQuickAction?: Maybe<CreateQuickActionPayload>
  creditQuotaGlobal?: Maybe<CreditQuotaGlobalPayload>
  deleteAccount?: Maybe<DeleteAccountPayload>
  deleteCreditCard?: Maybe<DeleteCreditCardPayload>
  deleteCustomDatabase?: Maybe<DeleteCustomDatabasePayload>
  deleteCustomFile?: Maybe<DeleteCustomFilePayload>
  deleteInvite?: Maybe<DeleteInvitePayload>
  deleteQuestion?: Maybe<DeleteQuestionPayload>
  deleteQuickAction?: Maybe<DeleteQuickActionPayload>
  extendTrial?: Maybe<ExtendTrialPayload>
  favoriteQuestion?: Maybe<FavoriteQuestionPayload>
  forkPad?: Maybe<ForkPadPayload>
  hubspotFormSubmit?: Maybe<HubspotFormSubmitPayload>
  joinOrganization?: Maybe<JoinOrganizationPayload>
  migrateUserToCognitoGlobal?: Maybe<MigrateUserToCognitoGlobalPayload>
  padAssignOrganization?: Maybe<PadAssignOrganizationPayload>
  padEmailInviteSend?: Maybe<PadEmailInviteSendPayload>
  padShortlinkGenerate?: Maybe<PadShortlinkGeneratePayload>
  previewUpdateQuestion?: Maybe<PreviewUpdateQuestionPayload>
  reactivateSubscription?: Maybe<ReactivateSubscriptionPayload>
  removeOrganizationMember?: Maybe<RemoveOrganizationMemberPayload>
  resendInvite?: Maybe<ResendInvitePayload>
  resetApiKey?: Maybe<ResetApiKeyPayload>
  sendInvites?: Maybe<SendInvitesPayload>
  updateCustomDatabase?: Maybe<UpdateCustomDatabasePayload>
  updateCustomFile?: Maybe<UpdateCustomFilePayload>
  updateInterviewNotesPrivacySettingForPad?: Maybe<UpdateInterviewNotesPrivacySettingForPadPayload>
  updateOrganizationGlobal?: Maybe<UpdateOrganizationGlobalPayload>
  updateOrganizationMember?: Maybe<UpdateOrganizationMemberPayload>
  updateOrganizationSettings?: Maybe<UpdateOrganizationSettingsPayload>
  updatePads?: Maybe<UpdatePadsPayload>
  updateQuestion?: Maybe<UpdateQuestionPayload>
  updateQuotaGlobal?: Maybe<UpdateQuotaGlobalPayload>
  updateUser?: Maybe<UpdateUserPayload>
  updateUserGlobal?: Maybe<UpdateUserGlobalPayload>
}

export type MutationAutocreateUserGlobalArgs = {
  input: AutocreateUserGlobalInput
}

export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput
}

export type MutationConfirmQuotaGlobalArgs = {
  input: ConfirmQuotaGlobalInput
}

export type MutationConsumeQuotaGlobalArgs = {
  input: ConsumeQuotaGlobalInput
}

export type MutationCreateCustomDatabaseArgs = {
  input: CreateCustomDatabaseInput
}

export type MutationCreateCustomFileArgs = {
  input: CreateCustomFileInput
}

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput
}

export type MutationCreateOrganizationGlobalArgs = {
  input: CreateOrganizationGlobalInput
}

export type MutationCreatePadArgs = {
  input: CreatePadInput
}

export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput
}

export type MutationCreateQuestionFromEnvironmentArgs = {
  input: CreateQuestionFromEnvironmentInput
}

export type MutationCreateQuickActionArgs = {
  input: CreateQuickActionInput
}

export type MutationCreditQuotaGlobalArgs = {
  input: CreditQuotaGlobalInput
}

export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput
}

export type MutationDeleteCreditCardArgs = {
  input: DeleteCreditCardInput
}

export type MutationDeleteCustomDatabaseArgs = {
  input: DeleteCustomDatabaseInput
}

export type MutationDeleteCustomFileArgs = {
  input: DeleteCustomFileInput
}

export type MutationDeleteInviteArgs = {
  input: DeleteInviteInput
}

export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput
}

export type MutationDeleteQuickActionArgs = {
  input: DeleteQuickActionInput
}

export type MutationExtendTrialArgs = {
  input: ExtendTrialInput
}

export type MutationFavoriteQuestionArgs = {
  input: FavoriteQuestionInput
}

export type MutationForkPadArgs = {
  input: ForkPadInput
}

export type MutationHubspotFormSubmitArgs = {
  input: HubspotFormSubmitInput
}

export type MutationJoinOrganizationArgs = {
  input: JoinOrganizationInput
}

export type MutationMigrateUserToCognitoGlobalArgs = {
  input: MigrateUserToCognitoGlobalInput
}

export type MutationPadAssignOrganizationArgs = {
  input: PadAssignOrganizationInput
}

export type MutationPadEmailInviteSendArgs = {
  input: PadEmailInviteSendInput
}

export type MutationPadShortlinkGenerateArgs = {
  input: PadShortlinkGenerateInput
}

export type MutationPreviewUpdateQuestionArgs = {
  input: PreviewUpdateQuestionInput
}

export type MutationReactivateSubscriptionArgs = {
  input: ReactivateSubscriptionInput
}

export type MutationRemoveOrganizationMemberArgs = {
  input: RemoveOrganizationMemberInput
}

export type MutationResendInviteArgs = {
  input: ResendInviteInput
}

export type MutationResetApiKeyArgs = {
  input: ResetApiKeyInput
}

export type MutationSendInvitesArgs = {
  input: SendInvitesInput
}

export type MutationUpdateCustomDatabaseArgs = {
  input: UpdateCustomDatabaseInput
}

export type MutationUpdateCustomFileArgs = {
  input: UpdateCustomFileInput
}

export type MutationUpdateInterviewNotesPrivacySettingForPadArgs = {
  input: UpdateInterviewNotesPrivacySettingForPadInput
}

export type MutationUpdateOrganizationGlobalArgs = {
  input: UpdateOrganizationGlobalInput
}

export type MutationUpdateOrganizationMemberArgs = {
  input: UpdateOrganizationMemberInput
}

export type MutationUpdateOrganizationSettingsArgs = {
  input: UpdateOrganizationSettingsInput
}

export type MutationUpdatePadsArgs = {
  input: UpdatePadsInput
}

export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput
}

export type MutationUpdateQuotaGlobalArgs = {
  input: UpdateQuotaGlobalInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserGlobalArgs = {
  input: UpdateUserGlobalInput
}

export type Organization = {
  __typename?: 'Organization'
  activeQuota: Quota
  aiChatEnabled: Scalars['Boolean']
  allowMemberPadCreation: Scalars['Boolean']
  allowMembersToInvite: Scalars['Boolean']
  autoDeletePads: Scalars['Boolean']
  autoDeletePadsAccessible: Scalars['Boolean']
  autoDeletePeriod: AutoDeletePeriods
  autoEndPads: Scalars['Boolean']
  autoEndPadsAccessible: Scalars['Boolean']
  autoEndPeriod: AutoEndPeriods
  childOrganizations: Array<Organization>
  cognitoSsoConfigured: Scalars['Boolean']
  cognitoSsoEnforced: Scalars['Boolean']
  cognitoSsoSamlConfig?: Maybe<CognitoSsoSamlConfig>
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  customFileDownloadEnabled: Scalars['Boolean']
  customIntro?: Maybe<Scalars['String']>
  customIntroFirstTimeUserContentEnabled: Scalars['Boolean']
  defaultExecution: Scalars['Boolean']
  defaultLanguage: Scalars['String']
  defaultWaitingRoom?: Maybe<Scalars['Boolean']>
  focusTimeSupported: Scalars['Boolean']
  hasActiveTrial: Scalars['Boolean']
  hasParentOrganization: Scalars['Boolean']
  hasPublicTakeHomeSettings: Scalars['Boolean']
  id: Scalars['Int']
  idGlobal: Scalars['String']
  idpCertFingerprint?: Maybe<Scalars['String']>
  idpEnabled?: Maybe<Scalars['Boolean']>
  idpInitUrl?: Maybe<Scalars['String']>
  idpIssuer?: Maybe<Scalars['String']>
  idpSloTargetUrl?: Maybe<Scalars['String']>
  idpSsoTargetUrl?: Maybe<Scalars['String']>
  isParentOrganization: Scalars['Boolean']
  joinableDomains: Array<JoinableOrganizationDomain>
  leverApiKey?: Maybe<Scalars['String']>
  leverPadUserId?: Maybe<Scalars['Int']>
  leverStageIdsForPadCreation: Array<Scalars['String']>
  leverWebhookSignatureToken?: Maybe<Scalars['String']>
  leverWebhookUrl: Scalars['String']
  loginSubdomain?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  logoAttached: Scalars['Boolean']
  name: Scalars['String']
  notesPrivateDefault: Scalars['Boolean']
  numOfAdmins: Scalars['Int']
  numOfMembers: Scalars['Int']
  ownerEmails: Array<Scalars['String']>
  padIndexEnabled: Scalars['Boolean']
  padsCount: Scalars['Int']
  parentOrganization?: Maybe<Organization>
  planId: Scalars['Int']
  planTitle: Scalars['String']
  publicTakeHomeSettingsCount: Scalars['Int']
  questionsEnabled: Scalars['Boolean']
  questionsRestricted: Scalars['Boolean']
  samlSsoConfigured: Scalars['Boolean']
  scimAuthToken?: Maybe<Scalars['String']>
  sharedQuestionEditingEnabled: Scalars['Boolean']
  singleSignOnUrl?: Maybe<Scalars['String']>
  ssoConfigurationAccessible: Scalars['Boolean']
  ssoEnforced?: Maybe<Scalars['Boolean']>
  ssoKind?: Maybe<SsoKind>
  takeHomesEnabled?: Maybe<Scalars['Boolean']>
  teams?: Maybe<Array<Team>>
  transcriptionsEnabled: Scalars['Boolean']
  transcriptionsFeatureAccessible: Scalars['Boolean']
  tsaDefocusNotificationEnabled: Scalars['Boolean']
  tsaDefocusPlaybackEnabled: Scalars['Boolean']
  tsaPasteNotificationEnabled: Scalars['Boolean']
  tsaPastePlaybackEnabled: Scalars['Boolean']
  tsaRealtimeFeatureAccessible: Scalars['Boolean']
  users: Array<User>
  usersCount: Scalars['Int']
}

/** Attributes to create an organization */
export type OrganizationCreateAttributes = {
  defaultLanguage: Scalars['String']
  name: Scalars['String']
}

/** Attributes for organization member update */
export type OrganizationMemberUpdateAttributes = {
  id: Scalars['Int']
  organizationId?: Maybe<Scalars['Int']>
  organizationOwner?: Maybe<Scalars['Boolean']>
}

export type OrganizationMembersSearchResults = {
  __typename?: 'OrganizationMembersSearchResults'
  pageNumber: Scalars['Int']
  pagesTotal: Scalars['Int']
  records: Array<User>
  totalRecords: Scalars['Int']
}

export type OrganizationPadUsage = {
  __typename?: 'OrganizationPadUsage'
  allCreated: Scalars['Int']
  liveEnded: Scalars['Int']
  liveStarted: Scalars['Int']
  takeHomeEnded: Scalars['Int']
  takeHomeStarted: Scalars['Int']
}

/** Attributes to update the current organization */
export type OrganizationUpdateAttributes = {
  aiChatEnabled?: Maybe<Scalars['Boolean']>
  autoDeletePads?: Maybe<Scalars['Boolean']>
  autoDeletePeriod?: Maybe<AutoDeletePeriods>
  autoEndPads?: Maybe<Scalars['Boolean']>
  autoEndPeriod?: Maybe<AutoEndPeriods>
  customFileDownloadEnabled?: Maybe<Scalars['Boolean']>
  customIntro?: Maybe<Scalars['String']>
  defaultExecution?: Maybe<Scalars['Boolean']>
  defaultLanguage?: Maybe<Scalars['String']>
  defaultWaitingRoom?: Maybe<Scalars['Boolean']>
  deleteLogo?: Maybe<Scalars['Boolean']>
  focusTimeSupported?: Maybe<Scalars['Boolean']>
  idpCertFingerprint?: Maybe<Scalars['String']>
  idpEnabled?: Maybe<Scalars['Boolean']>
  idpIssuer?: Maybe<Scalars['String']>
  idpSloTargetUrl?: Maybe<Scalars['String']>
  idpSsoTargetUrl?: Maybe<Scalars['String']>
  joinableDomainsAttributes?: Maybe<Array<JoinableOrganizationDomainUpdateAttributes>>
  leverApiKey?: Maybe<Scalars['String']>
  leverPadUserId?: Maybe<Scalars['Int']>
  leverStageIdsForPadCreation?: Maybe<Array<Scalars['String']>>
  leverWebhookSignatureToken?: Maybe<Scalars['String']>
  loginSubdomain?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['Upload']>
  name?: Maybe<Scalars['String']>
  notesPrivateDefault?: Maybe<Scalars['Boolean']>
  padIndexEnabled?: Maybe<Scalars['Boolean']>
  questionsEnabled?: Maybe<Scalars['Boolean']>
  questionsRestricted?: Maybe<Scalars['Boolean']>
  sharedQuestionEditingEnabled?: Maybe<Scalars['Boolean']>
  transcriptionsEnabled?: Maybe<Scalars['Boolean']>
  tsaDefocusNotificationEnabled?: Maybe<Scalars['Boolean']>
  tsaDefocusPlaybackEnabled?: Maybe<Scalars['Boolean']>
  tsaPasteNotificationEnabled?: Maybe<Scalars['Boolean']>
  tsaPastePlaybackEnabled?: Maybe<Scalars['Boolean']>
}

/** Sort options for User */
export enum OrganizationUserAndInviteSort {
  EmailAsc = 'emailAsc',
  EmailDesc = 'emailDesc',
  NameAsc = 'nameAsc',
  NameDesc = 'nameDesc',
  OrganizationNameAsc = 'organizationNameAsc',
  OrganizationNameDesc = 'organizationNameDesc',
  OrganizationOwnerAsc = 'organizationOwnerAsc',
  OrganizationOwnerDesc = 'organizationOwnerDesc',
}

export type OrganizationUsersAndInvites = {
  __typename?: 'OrganizationUsersAndInvites'
  createdAt: Scalars['ISO8601DateTime']
  email: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  orgMemberModificationAllowed?: Maybe<Scalars['Boolean']>
  organization: Organization
  organizationOwner: Scalars['Boolean']
  role: UserAndInviteRole
  type: UserAndInviteType
}

export type OrganizationUsersAndInvitesSearchResults = {
  __typename?: 'OrganizationUsersAndInvitesSearchResults'
  pageNumber: Scalars['Int']
  pagesTotal: Scalars['Int']
  records: Array<OrganizationUsersAndInvites>
  totalRecords: Scalars['Int']
}

export type Pad = {
  __typename?: 'Pad'
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  hasTranscription?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  language?: Maybe<Scalars['String']>
  notesPrivate: Scalars['Boolean']
  organization?: Maybe<Organization>
  projectTemplate?: Maybe<ProjectTemplate>
  publicTakeHomeSubmissionDetails?: Maybe<PublicTakeHomeSubmissionDetails>
  slug?: Maybe<Scalars['String']>
  state: Scalars['String']
  stateDisplayable: Scalars['String']
  takeHome: Scalars['Boolean']
  takeHomeAutoSubmitted?: Maybe<Scalars['Boolean']>
  takeHomeTimeLimit?: Maybe<Scalars['Int']>
  team?: Maybe<Team>
  title?: Maybe<Scalars['String']>
  uiType: PadUiTypes
  user?: Maybe<User>
}

/** Autogenerated input type of PadAssignOrganization */
export type PadAssignOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  organizationId: Scalars['Int']
  padSlug: Scalars['String']
}

/** Autogenerated return type of PadAssignOrganization */
export type PadAssignOrganizationPayload = {
  __typename?: 'PadAssignOrganizationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message: Scalars['String']
  pad: Pad
}

/** Attributes for updating a pad */
export type PadAttributes = {
  deleted?: Maybe<Scalars['Boolean']>
  ended?: Maybe<Scalars['Boolean']>
  slug: Scalars['String']
  teamId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

/** Attributes for creating a pad */
export type PadCreateAttributes = {
  executionEnabled?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  projectTemplateId?: Maybe<Scalars['Int']>
  teamId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uiType?: Maybe<PadUiTypes>
}

/** Autogenerated input type of PadEmailInviteSend */
export type PadEmailInviteSendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  email: Scalars['String']
  padSlug: Scalars['String']
}

/** Autogenerated return type of PadEmailInviteSend */
export type PadEmailInviteSendPayload = {
  __typename?: 'PadEmailInviteSendPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

/** Attributes for forking a pad */
export type PadForkAttributes = {
  slug: Scalars['String']
  title?: Maybe<Scalars['String']>
  toLive?: Maybe<Scalars['Boolean']>
}

/** Format options for Pad */
export enum PadFormat {
  PublicTakeHome = 'publicTakeHome',
  TakeHome = 'takeHome',
}

/** Attributes for updating a pad's interview notes privacy settings */
export type PadInterviewNotesPrivacySettingAttributes = {
  private: Scalars['Boolean']
  slug: Scalars['String']
}

export type PadLanguageUsage = {
  __typename?: 'PadLanguageUsage'
  languagesUsedInPad: Array<PadLanguageUsageStats>
  padsStartedByLanguage: Array<PadLanguageUsageStats>
}

export type PadLanguageUsageStats = {
  __typename?: 'PadLanguageUsageStats'
  count: Scalars['Int']
  language: Scalars['String']
}

/** Autogenerated input type of PadShortlinkGenerate */
export type PadShortlinkGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  padSlug: Scalars['String']
}

/** Autogenerated return type of PadShortlinkGenerate */
export type PadShortlinkGeneratePayload = {
  __typename?: 'PadShortlinkGeneratePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  shortlink?: Maybe<Scalars['String']>
}

/** Sort options for Pad */
export enum PadSort {
  CandidateNameAsc = 'candidateNameAsc',
  CandidateNameDesc = 'candidateNameDesc',
  CreatedAtAsc = 'createdAtAsc',
  CreatedAtDesc = 'createdAtDesc',
  NumHiddenPassedAsc = 'numHiddenPassedAsc',
  NumHiddenPassedDesc = 'numHiddenPassedDesc',
  NumVisiblePassedAsc = 'numVisiblePassedAsc',
  NumVisiblePassedDesc = 'numVisiblePassedDesc',
  SubmittedAtAsc = 'submittedAtAsc',
  SubmittedAtDesc = 'submittedAtDesc',
  TimeSpentAsc = 'timeSpentAsc',
  TimeSpentDesc = 'timeSpentDesc',
}

/** Pad ui type options */
export enum PadUiTypes {
  Coding = 'Coding',
  DrawingOnly = 'DrawingOnly',
}

export type PadsSearchResults = {
  __typename?: 'PadsSearchResults'
  /** @deprecated Expensive query, avoid use. */
  pagesTotal: Scalars['Int']
  records: Array<Pad>
  /** @deprecated Expensive query, avoid use. */
  totalRecords: Scalars['Int']
}

export type PendingInvitesSearchResults = {
  __typename?: 'PendingInvitesSearchResults'
  pageNumber: Scalars['Int']
  pagesTotal: Scalars['Int']
  records: Array<Invite>
  totalRecords: Scalars['Int']
}

/** Sort options for Pending Invites */
export enum PendingInvitesSort {
  CreatedAtAsc = 'createdAtAsc',
  CreatedAtDesc = 'createdAtDesc',
  EmailAsc = 'emailAsc',
  EmailDesc = 'emailDesc',
  RoleAsc = 'roleAsc',
  RoleDesc = 'roleDesc',
  SenderNameAsc = 'senderNameAsc',
  SenderNameDesc = 'senderNameDesc',
}

export type PeriodPadUsageStats = {
  __typename?: 'PeriodPadUsageStats'
  count: Scalars['Int']
  date: Scalars['ISO8601DateTime']
}

export type Plan = {
  __typename?: 'Plan'
  allowOverages: Scalars['Boolean']
  annual?: Maybe<Scalars['Boolean']>
  annualPrice?: Maybe<Scalars['Int']>
  billingCyclePadLimit?: Maybe<Scalars['Int']>
  canBeSelected: Scalars['Boolean']
  codingameXsellPlan: Scalars['Boolean']
  currency: Currency
  current: Scalars['Boolean']
  description: Scalars['String']
  formattedAnnualPrice?: Maybe<Scalars['String']>
  formattedMonthlyPrice?: Maybe<Scalars['String']>
  formattedOveragePrice?: Maybe<Scalars['String']>
  hasAnnualAlternative?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  legacy: Scalars['Boolean']
  legacyOf?: Maybe<Scalars['Int']>
  monthlyPrice?: Maybe<Scalars['Int']>
  overagePrice?: Maybe<Scalars['Int']>
  planCategorization: PlanCategorization
  title: Scalars['String']
  upgradeOrder: Scalars['Int']
  userAccountLimit?: Maybe<Scalars['Int']>
  widgetDescription: Scalars['String']
  widgetSubTitle: Scalars['String']
}

/** Valid plan categorization codes */
export enum PlanCategorization {
  Enterprise = 'enterprise',
  Free = 'free',
  NonEnterprise = 'non_enterprise',
}

/** Autogenerated input type of PreviewUpdateQuestion */
export type PreviewUpdateQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  previewKey?: Maybe<Scalars['Int']>
  questionAttributes: QuestionUpdateAttributes
}

/** Autogenerated return type of PreviewUpdateQuestion */
export type PreviewUpdateQuestionPayload = {
  __typename?: 'PreviewUpdateQuestionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  question?: Maybe<QuestionPreview>
}

export type ProjectTemplate = {
  __typename?: 'ProjectTemplate'
  category: Scalars['String']
  fileContents?: Maybe<Scalars['JSON']>
  id: Scalars['Int']
  latest: Scalars['Boolean']
  name: Scalars['String']
  settings: Scalars['JSON']
  slug: Scalars['String']
  version: Scalars['String']
}

/** Categories for ProjectTemplate */
export enum ProjectTemplateCategory {
  Backend = 'backend',
  Frontend = 'frontend',
  Jupyter = 'jupyter',
  Mobile = 'mobile',
  Multifile = 'multifile',
}

export type PublicTakeHomeSettingStats = {
  __typename?: 'PublicTakeHomeSettingStats'
  attemptsCount: Scalars['Int']
  passingAllTestCasesCount: Scalars['Int']
  pctAttemptsPassingAllTestCases: Scalars['Int']
  pctAttemptsSubmitted: Scalars['Int']
  pctSubmissionsNotPassingAllTestCases: Scalars['Int']
  pctSubmissionsPassingAllTestCases: Scalars['Int']
  publicTakeHomeSettingId: Scalars['Int']
  questionId: Scalars['Int']
  questionTitle: Scalars['String']
  readyForReviewCount: Scalars['Int']
  submissionsCount: Scalars['Int']
  timeLimit: Scalars['Int']
  timeLimitHuman: Scalars['String']
  timeSpentAvg?: Maybe<Scalars['Float']>
}

/** Stats for an organization's public take-homes */
export type PublicTakeHomeSettingsStats = {
  __typename?: 'PublicTakeHomeSettingsStats'
  byPublicTakeHomeSetting: Array<PublicTakeHomeSettingStats>
  summary?: Maybe<PublicTakeHomeSettingsStatsSummary>
}

export type PublicTakeHomeSettingsStatsSummary = {
  __typename?: 'PublicTakeHomeSettingsStatsSummary'
  attemptsCount: Scalars['Int']
  passingAllTestCasesCount: Scalars['Int']
  pctAttemptsPassingAllTestCases: Scalars['Int']
  pctAttemptsSubmitted: Scalars['Int']
  pctSubmissionsNotPassingAllTestCases: Scalars['Int']
  pctSubmissionsPassingAllTestCases: Scalars['Int']
  readyForReviewCount: Scalars['Int']
  submissionsCount: Scalars['Int']
}

/** Returns public take-home submission details for a given Pad object */
export type PublicTakeHomeSubmissionDetails = {
  __typename?: 'PublicTakeHomeSubmissionDetails'
  candidateEmail: Scalars['String']
  candidateName?: Maybe<Scalars['String']>
  challengeTitle: Scalars['String']
  hiddenTestCasesPassed: Scalars['Int']
  hiddenTestCasesTotal: Scalars['Int']
  submittedTimeAgo?: Maybe<Scalars['String']>
  timeLimit: Scalars['Int']
  timeSpent: Scalars['Int']
  visibleTestCasesPassed: Scalars['Int']
  visibleTestCasesTotal: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  /** Pad usage details for an organization or user */
  accountPadUsage?: Maybe<AccountPadUsage>
  /** List auto delete periods */
  autoDeletePeriods?: Maybe<Array<AutoDeletePeriod>>
  /** List auto end periods */
  autoEndPeriods?: Maybe<Array<AutoEndPeriod>>
  /** Custom database details */
  customDatabase?: Maybe<CustomDatabase>
  /** Find eligible custom databases for a user */
  customDatabasesSearch?: Maybe<CustomDatabasesSearchResults>
  /** Custom file details */
  customFile?: Maybe<CustomFile>
  /** Find all custom file extensions for a user */
  customFilesExtensions?: Maybe<Array<Scalars['String']>>
  /** Find viewable custom files for a user */
  customFilesSearch?: Maybe<CustomFilesSearchResults>
  /** Sso kind for an email domain */
  emailDomain?: Maybe<EmailDomain>
  /** Used by CodinGame to make a server-to-server request to check if user exists with the same email */
  emailExists: Scalars['Boolean']
  /** Example Question details */
  exampleQuestion?: Maybe<ExampleQuestion>
  /** Find example questions */
  exampleQuestionsSearch?: Maybe<ExampleQuestionsSearchResults>
  getUserInformation?: Maybe<UserInformation>
  /** List global question tags */
  globalQuestionTags?: Maybe<Array<GlobalQuestionTag>>
  /** Used by CodinGame to get invite by token */
  inviteByToken?: Maybe<Invite>
  /** Look up details about an invite */
  inviteDetails?: Maybe<Invite>
  /** Used by CodinGame to get invited users from their emails */
  invitesByEmails: Array<Invite>
  /** Find invoice for the given id */
  invoice?: Maybe<Invoice>
  joinableOrganizations?: Maybe<Array<Organization>>
  /** List supported languages */
  languages?: Maybe<Array<Language>>
  /** Get Lever integration stage options */
  leverStageOptions: Array<LeverStageOption>
  /** User login method */
  loginMethod?: Maybe<LoginMethod>
  organization?: Maybe<Organization>
  /** Used by CodinGame to get organization from organization_id_global */
  organizationGlobal?: Maybe<Organization>
  /** Used by CodinGame to get invites in organization from organization_id_global */
  organizationInvites: Array<Invite>
  /** Find members for the current org */
  organizationMembersSearch?: Maybe<OrganizationMembersSearchResults>
  /** Pad usage for an organization */
  organizationPadUsage?: Maybe<OrganizationPadUsage>
  /** Used by CodinGame to get the emails belonging to an organization */
  organizationUserEmails: Array<Scalars['String']>
  /** Used by CodinGame to get users in organization from organization_id_global */
  organizationUsers: Array<User>
  organizationUsersAndInvitesSearch?: Maybe<OrganizationUsersAndInvitesSearchResults>
  /** Languages usage in pads */
  padLanguageUsage?: Maybe<PadLanguageUsage>
  /** Find all pads for a user */
  padsSearch?: Maybe<PadsSearchResults>
  /** Find pending invites for the current organization */
  pendingInvitesSearch?: Maybe<PendingInvitesSearchResults>
  plan?: Maybe<Plan>
  /** Find live plans in the currently active, or provided, currency */
  plans?: Maybe<Array<Plan>>
  /** Project Template details */
  projectTemplate?: Maybe<ProjectTemplate>
  /** Available Project Templates */
  projectTemplates?: Maybe<Array<ProjectTemplate>>
  /** Stats for public take-home submissions */
  publicTakeHomeSettingsStats?: Maybe<PublicTakeHomeSettingsStats>
  /** Question details */
  question?: Maybe<Question>
  /** Pad usage with and without questions */
  questionsPadUsage?: Maybe<QuestionsPadUsage>
  /** Find all questions for a user */
  questionsSearch?: Maybe<QuestionsSearchResults>
  /** Find user's quick actions for the Pads view */
  quickActions?: Maybe<Array<QuickAction>>
  quota?: Maybe<Quota>
  /** Get the screen organization config if any */
  screenOrganizationConfig?: Maybe<ScreenOrganizationConfig>
  /** Snippet details */
  snippet?: Maybe<Snippet>
  /** Get SSO config from idp_name */
  ssoConfigByIdpName: LoginMethod
  /** Starter code based on test cases parameters and data types */
  testCasesStarterCode?: Maybe<TestCasesStarterCode>
  user?: Maybe<User>
  /** Retrieve a user by their cognito username */
  userGlobal?: Maybe<User>
  /** Used by CodinGame to get possible user Persona Roles */
  userPersonaRoles: Array<Scalars['String']>
  /** Used by CodinGame to get users from their emails or cognito_usernames */
  usersByEmailsOrCognitoUsernames: Array<User>
  validateCredentials?: Maybe<CrendentialValidity>
}

export type QueryAccountPadUsageArgs = {
  organizationId?: Maybe<Scalars['Int']>
  period?: Maybe<UsagePeriod>
  year?: Maybe<Scalars['Int']>
}

export type QueryCustomDatabaseArgs = {
  id: Scalars['Int']
}

export type QueryCustomDatabasesSearchArgs = {
  language?: Maybe<Scalars['String']>
  padLanguage?: Maybe<LanguageKey>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<CustomDatabasesSort>
  state?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  userOnly?: Maybe<Scalars['Boolean']>
}

export type QueryCustomFileArgs = {
  id: Scalars['String']
}

export type QueryCustomFilesSearchArgs = {
  extension?: Maybe<Scalars['String']>
  filter?: Maybe<Scalars['String']>
  onlyUserFiles?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<CustomFileSort>>
}

export type QueryEmailDomainArgs = {
  domain: Scalars['String']
}

export type QueryEmailExistsArgs = {
  email: Scalars['String']
}

export type QueryExampleQuestionArgs = {
  id: Scalars['String']
}

export type QueryExampleQuestionsSearchArgs = {
  difficulty?: Maybe<QuestionDifficulty>
  globalQuestionTagIds?: Maybe<Array<Scalars['Int']>>
  language?: Maybe<Scalars['String']>
  padTypes?: Maybe<Array<QuestionPadTypes>>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<ExampleQuestionSort>>
  testCasesEnabled?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
}

export type QueryGetUserInformationArgs = {
  email: Scalars['String']
}

export type QueryInviteByTokenArgs = {
  token: Scalars['String']
}

export type QueryInviteDetailsArgs = {
  token: Scalars['String']
}

export type QueryInvitesByEmailsArgs = {
  emails: Array<Scalars['String']>
}

export type QueryInvoiceArgs = {
  id: Scalars['String']
}

export type QueryLoginMethodArgs = {
  email: Scalars['String']
}

export type QueryOrganizationGlobalArgs = {
  organizationIdGlobal: Scalars['String']
}

export type QueryOrganizationInvitesArgs = {
  organizationIdGlobal: Scalars['String']
}

export type QueryOrganizationMembersSearchArgs = {
  emailFilter?: Maybe<Scalars['String']>
  firebaseIds?: Maybe<Array<Scalars['String']>>
  isPowerUserQuery?: Maybe<Scalars['Boolean']>
  nameOrEmailFilter?: Maybe<Scalars['String']>
  organizationId?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sinceDays?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<UserSort>>
  text?: Maybe<Scalars['String']>
}

export type QueryOrganizationPadUsageArgs = {
  organizationId?: Maybe<Scalars['Int']>
  sinceDays?: Maybe<Scalars['Int']>
}

export type QueryOrganizationUserEmailsArgs = {
  organizationIdGlobal: Scalars['String']
}

export type QueryOrganizationUsersArgs = {
  includeInactive?: Maybe<Scalars['Boolean']>
  organizationIdGlobal: Scalars['String']
}

export type QueryOrganizationUsersAndInvitesSearchArgs = {
  invites?: Maybe<Scalars['Boolean']>
  organizationId?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<OrganizationUserAndInviteSort>>
  text?: Maybe<Scalars['String']>
  users?: Maybe<Scalars['Boolean']>
}

export type QueryPadLanguageUsageArgs = {
  organizationId?: Maybe<Scalars['Int']>
}

export type QueryPadsSearchArgs = {
  createdAtGreaterThan?: Maybe<Scalars['Float']>
  format?: Maybe<PadFormat>
  minTotalTestCasesPassed?: Maybe<Scalars['Int']>
  minTotalTestCasesPassedPct?: Maybe<Scalars['Int']>
  organizationId?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  questionIds?: Maybe<Array<Scalars['Int']>>
  sort?: Maybe<Array<PadSort>>
  state?: Maybe<Scalars['String']>
  stateNot?: Maybe<Scalars['String']>
  submittedAtGreaterThan?: Maybe<Scalars['Float']>
  takeHome?: Maybe<Scalars['Boolean']>
  teamId?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  uiType?: Maybe<PadUiTypes>
  userOnly?: Maybe<Scalars['Boolean']>
}

export type QueryPendingInvitesSearchArgs = {
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<PendingInvitesSort>>
}

export type QueryPlansArgs = {
  currency?: Maybe<Currency>
}

export type QueryProjectTemplateArgs = {
  slug: Scalars['String']
}

export type QueryProjectTemplatesArgs = {
  category?: Maybe<ProjectTemplateCategory>
  padSlug?: Maybe<Scalars['String']>
}

export type QueryQuestionArgs = {
  id: Scalars['Int']
}

export type QueryQuestionsPadUsageArgs = {
  organizationId?: Maybe<Scalars['Int']>
  sinceDays?: Maybe<Scalars['Int']>
}

export type QueryQuestionsSearchArgs = {
  difficulty?: Maybe<QuestionDifficulty>
  isDraft?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  organizationId?: Maybe<Scalars['Int']>
  padTypes?: Maybe<Array<QuestionPadTypes>>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<QuestionSort>>
  takeHome?: Maybe<Scalars['Boolean']>
  testCasesEnabled?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
  userOnly?: Maybe<Scalars['Boolean']>
}

export type QueryQuickActionsArgs = {
  page: QuickActionsPage
}

export type QuerySnippetArgs = {
  slug: Scalars['String']
}

export type QuerySsoConfigByIdpNameArgs = {
  idpName: Scalars['String']
}

export type QueryTestCasesStarterCodeArgs = {
  language: LanguageKey
  parameters: Array<QuestionParameterAttributes>
  returnValueDataType: TestCaseDataTypes
}

export type QueryUserGlobalArgs = {
  cognitoUsername?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type QueryUsersByEmailsOrCognitoUsernamesArgs = {
  cognitoUsernames: Array<Scalars['String']>
  emails: Array<Scalars['String']>
  includeInactive?: Maybe<Scalars['Boolean']>
}

export type QueryValidateCredentialsArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type Question = {
  __typename?: 'Question'
  candidateInstructions?: Maybe<Array<CandidateInstructions>>
  contents?: Maybe<Scalars['String']>
  contentsForTestCases?: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  createdTimeAgo?: Maybe<Scalars['String']>
  customDatabase?: Maybe<CustomDatabase>
  customFiles?: Maybe<Array<CustomFile>>
  defaultTakeHomeTimeLimit?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  difficulty?: Maybe<QuestionDifficulty>
  draftValidationErrors?: Maybe<Array<Error>>
  drawing?: Maybe<Drawing>
  eligibleCustomDatabases?: Maybe<Array<CustomDatabase>>
  eligibleCustomFiles?: Maybe<Array<CustomFile>>
  favorited: Scalars['Boolean']
  fileContents?: Maybe<Scalars['JSON']>
  global?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  isDraft?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  lastUsedAt?: Maybe<Scalars['ISO8601DateTime']>
  ownerEmail: Scalars['String']
  padType: QuestionPadTypes
  parameters?: Maybe<Array<QuestionParameter>>
  projectTemplate?: Maybe<ProjectTemplate>
  publicTakeHomeSettingId?: Maybe<Scalars['Int']>
  publicUrl?: Maybe<Scalars['String']>
  questionVariants: Array<QuestionVariant>
  returnValueDataType?: Maybe<TestCaseDataTypes>
  shared?: Maybe<Scalars['Boolean']>
  solution?: Maybe<Scalars['String']>
  spreadsheet?: Maybe<Spreadsheet>
  starterCodeByLanguage?: Maybe<Scalars['JSON']>
  takeHome?: Maybe<Scalars['Boolean']>
  testCases?: Maybe<Array<TestCase>>
  testCasesEnabled?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['ISO8601DateTime']
  used?: Maybe<Scalars['Int']>
  user?: Maybe<User>
  viewableByUser: Scalars['Boolean']
}

/** Attributes for question create */
export type QuestionCreateAttributes = {
  candidateInstructions?: Maybe<Array<CandidateInstructionsUpdate>>
  contents?: Maybe<Scalars['String']>
  customDatabaseId?: Maybe<Scalars['Int']>
  customFileIds?: Maybe<Array<Scalars['String']>>
  defaultTakeHomeTimeLimit?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  difficulty?: Maybe<QuestionDifficulty>
  drawing?: Maybe<DrawingAttributes>
  fileContents?: Maybe<Scalars['JSON']>
  includeStarterDrawing?: Maybe<Scalars['Boolean']>
  isDraft?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  padType?: Maybe<QuestionPadTypes>
  parametersAttributes?: Maybe<Array<QuestionParameterCreateAttributes>>
  projectTemplateId?: Maybe<Scalars['Int']>
  returnValueDataType?: Maybe<TestCaseDataTypes>
  shared?: Maybe<Scalars['Boolean']>
  solution?: Maybe<Scalars['String']>
  spreadsheetId?: Maybe<Scalars['Int']>
  takeHome?: Maybe<Scalars['Boolean']>
  testCasesAttributes?: Maybe<Array<TestCaseCreateAttributes>>
  testCasesEnabled?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

/** Difficulty of a Question */
export enum QuestionDifficulty {
  Easy = 'Easy',
  Hard = 'Hard',
  Medium = 'Medium',
}

/** pad types of a question */
export enum QuestionPadTypes {
  Any = 'Any',
  Live = 'Live',
  TakeHome = 'TakeHome',
}

export type QuestionParameter = {
  __typename?: 'QuestionParameter'
  dataType: TestCaseDataTypes
  id: Scalars['Int']
  index: Scalars['Int']
  name: Scalars['String']
}

/** Attributes for test case starter code preview */
export type QuestionParameterAttributes = {
  dataType: TestCaseDataTypes
  name: Scalars['String']
}

/** Attributes for question parameter create */
export type QuestionParameterCreateAttributes = {
  dataType?: Maybe<TestCaseDataTypes>
  index?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type QuestionParameterPreview = {
  __typename?: 'QuestionParameterPreview'
  dataType: TestCaseDataTypes
  id?: Maybe<Scalars['Int']>
  index: Scalars['Int']
  name: Scalars['String']
}

/** Attributes for question parameter create and update */
export type QuestionParameterUpdateAttributes = {
  _destroy?: Maybe<Scalars['Boolean']>
  dataType?: Maybe<TestCaseDataTypes>
  id?: Maybe<Scalars['Int']>
  index?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type QuestionPreview = {
  __typename?: 'QuestionPreview'
  candidateInstructions?: Maybe<Array<CandidateInstructions>>
  contents?: Maybe<Scalars['String']>
  contentsForTestCases?: Maybe<Scalars['String']>
  createdAt: Scalars['ISO8601DateTime']
  createdTimeAgo?: Maybe<Scalars['String']>
  customDatabase?: Maybe<CustomDatabase>
  customFiles?: Maybe<Array<CustomFile>>
  defaultTakeHomeTimeLimit?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  difficulty?: Maybe<QuestionDifficulty>
  draftValidationErrors?: Maybe<Array<Error>>
  drawing?: Maybe<Drawing>
  eligibleCustomDatabases?: Maybe<Array<CustomDatabase>>
  eligibleCustomFiles?: Maybe<Array<CustomFile>>
  favorited: Scalars['Boolean']
  fileContents?: Maybe<Scalars['JSON']>
  global?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  isDraft?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  lastUsedAt?: Maybe<Scalars['ISO8601DateTime']>
  ownerEmail: Scalars['String']
  padType: QuestionPadTypes
  parameters?: Maybe<Array<QuestionParameterPreview>>
  projectTemplate?: Maybe<ProjectTemplate>
  publicTakeHomeSettingId?: Maybe<Scalars['Int']>
  publicUrl?: Maybe<Scalars['String']>
  questionVariants: Array<QuestionVariant>
  returnValueDataType?: Maybe<TestCaseDataTypes>
  shared?: Maybe<Scalars['Boolean']>
  solution?: Maybe<Scalars['String']>
  spreadsheet?: Maybe<Spreadsheet>
  starterCodeByLanguage?: Maybe<Scalars['JSON']>
  takeHome?: Maybe<Scalars['Boolean']>
  testCases?: Maybe<Array<TestCasePreview>>
  testCasesEnabled?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['ISO8601DateTime']
  used?: Maybe<Scalars['Int']>
  user?: Maybe<User>
  viewableByUser: Scalars['Boolean']
}

/** Sort options for Question */
export enum QuestionSort {
  CreatedAtAsc = 'createdAtAsc',
  CreatedAtDesc = 'createdAtDesc',
  FavoritedAsc = 'favoritedAsc',
  FavoritedDesc = 'favoritedDesc',
  LastUsedAtAsc = 'lastUsedAtAsc',
  LastUsedAtDesc = 'lastUsedAtDesc',
  TitleAsc = 'titleAsc',
  TitleDesc = 'titleDesc',
  UsedAsc = 'usedAsc',
  UsedDesc = 'usedDesc',
}

/** Attributes for question update */
export type QuestionUpdateAttributes = {
  candidateInstructions?: Maybe<Array<CandidateInstructionsUpdate>>
  contents?: Maybe<Scalars['String']>
  customDatabaseId?: Maybe<Scalars['Int']>
  customFileIds?: Maybe<Array<Scalars['String']>>
  defaultTakeHomeTimeLimit?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  difficulty?: Maybe<QuestionDifficulty>
  drawing?: Maybe<DrawingAttributes>
  id: Scalars['Int']
  includeStarterDrawing?: Maybe<Scalars['Boolean']>
  isDraft?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  padType?: Maybe<QuestionPadTypes>
  parametersAttributes?: Maybe<Array<QuestionParameterUpdateAttributes>>
  projectTemplateId?: Maybe<Scalars['Int']>
  returnValueDataType?: Maybe<TestCaseDataTypes>
  shared?: Maybe<Scalars['Boolean']>
  solution?: Maybe<Scalars['String']>
  takeHome?: Maybe<Scalars['Boolean']>
  testCasesAttributes?: Maybe<Array<TestCaseUpdateAttributes>>
  testCasesEnabled?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export type QuestionVariant = {
  __typename?: 'QuestionVariant'
  display: Scalars['String']
  fileContents?: Maybe<Scalars['JSON']>
  id: Scalars['Int']
  language?: Maybe<Scalars['String']>
  projectTemplate?: Maybe<ProjectTemplate>
  solution?: Maybe<Scalars['String']>
}

/** Question creation wizard steps and their associated fields */
export enum QuestionWizardStep {
  Code = 'Code',
  Context = 'Context',
  Preview = 'Preview',
  TestCases = 'TestCases',
}

export type QuestionsPadUsage = {
  __typename?: 'QuestionsPadUsage'
  padsWithQuestions: Scalars['Int']
  padsWithoutQuestions: Scalars['Int']
}

export type QuestionsSearchResults = {
  __typename?: 'QuestionsSearchResults'
  pageNumber: Scalars['Int']
  pagesTotal: Scalars['Int']
  records: Array<Question>
  totalRecords: Scalars['Int']
}

export type QuickAction = {
  __typename?: 'QuickAction'
  id: Scalars['String']
  name: QuickActionName
  payload: Scalars['JSON']
}

/** Attributes to create a quick action */
export type QuickActionCreateAttributes = {
  name: QuickActionName
  page: QuickActionsPage
  payload: Scalars['JSON']
}

/** Attributes to delete a quick action */
export type QuickActionDeleteAttributes = {
  id: Scalars['String']
  page: QuickActionsPage
}

/** Valid QuickAction names */
export enum QuickActionName {
  CreatePad = 'createPad',
  CreateQuestion = 'createQuestion',
}

/** Valid values for the :page argument of the quickAction query. */
export enum QuickActionsPage {
  Pads = 'pads',
}

export type Quota = {
  __typename?: 'Quota'
  accountScheduledForDeletionAt?: Maybe<Scalars['String']>
  addressGiven: Scalars['Boolean']
  allowConsumption: Scalars['Boolean']
  allowCreditCardDeletion?: Maybe<Scalars['Boolean']>
  allowTurningOverageOff: Scalars['Boolean']
  billable: Billable
  billingCyclePadLimit?: Maybe<Scalars['Int']>
  bulk: Scalars['Boolean']
  businessAddress?: Maybe<BusinessAddress>
  canAutorenewEarly?: Maybe<Scalars['Boolean']>
  canceled?: Maybe<Scalars['Boolean']>
  cardLast4?: Maybe<Scalars['String']>
  cardType?: Maybe<Scalars['String']>
  codingameUnlimitedTestsOffer: Scalars['Boolean']
  consumeOnStart: Scalars['Boolean']
  country?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  everPaid: Scalars['Boolean']
  exampleQuestionsEnabled?: Maybe<Scalars['Boolean']>
  expiredTrial: Scalars['Boolean']
  extraRecipients?: Maybe<Scalars['String']>
  hasCreditCard?: Maybe<Scalars['Boolean']>
  humanOverageCost?: Maybe<Scalars['String']>
  id: Scalars['Int']
  ignoreQuota: Scalars['Boolean']
  invoicedCustomer: Scalars['Boolean']
  invoices?: Maybe<Array<Invoice>>
  nextPaymentDateDisplayableLong?: Maybe<Scalars['String']>
  nextPaymentDateDisplayableShort?: Maybe<Scalars['String']>
  offerTrialExtension: Scalars['Boolean']
  overagePadsUsed: Scalars['Int']
  overagePrice?: Maybe<Scalars['Float']>
  overagesEnabled: Scalars['Boolean']
  padsRemaining?: Maybe<Scalars['Int']>
  padsUsed: Scalars['Int']
  paid: Scalars['Boolean']
  plan: Scalars['Int']
  planIntent?: Maybe<Scalars['Int']>
  planObj?: Maybe<Plan>
  quotaAvailable: Scalars['Boolean']
  quotaLimit?: Maybe<Scalars['Int']>
  quotaResetAt?: Maybe<Scalars['ISO8601DateTime']>
  quotaWarning: Scalars['Boolean']
  serviceEnd?: Maybe<Scalars['ISO8601DateTime']>
  serviceEndDisplayable?: Maybe<Scalars['String']>
  serviceStartAt?: Maybe<Scalars['ISO8601DateTime']>
  sharedCredits: Scalars['Boolean']
  sharedCreditsWithCoderpadBilling: Scalars['Boolean']
  sharedCreditsWithCodingameBilling: Scalars['Boolean']
  stripeId?: Maybe<Scalars['String']>
  transcriptionsEnabled: Scalars['Boolean']
  trial: Scalars['Boolean']
  trialExpiresAt?: Maybe<Scalars['ISO8601DateTime']>
  trialStartedAt?: Maybe<Scalars['ISO8601DateTime']>
  unlimited: Scalars['Boolean']
  useSharedCreditsWording: Scalars['Boolean']
}

/** Autogenerated input type of ReactivateSubscription */
export type ReactivateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of ReactivateSubscription */
export type ReactivateSubscriptionPayload = {
  __typename?: 'ReactivateSubscriptionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message: Scalars['String']
  quota: Quota
}

/** Autogenerated input type of RemoveOrganizationMember */
export type RemoveOrganizationMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

/** Autogenerated return type of RemoveOrganizationMember */
export type RemoveOrganizationMemberPayload = {
  __typename?: 'RemoveOrganizationMemberPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of ResendInvite */
export type ResendInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

/** Autogenerated return type of ResendInvite */
export type ResendInvitePayload = {
  __typename?: 'ResendInvitePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  invite?: Maybe<Invite>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of ResetApiKey */
export type ResetApiKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of ResetApiKey */
export type ResetApiKeyPayload = {
  __typename?: 'ResetApiKeyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  user: User
}

export type ScreenOrganizationConfig = {
  __typename?: 'ScreenOrganizationConfig'
  appManagingUsers: Application
  legacySsoEnabled?: Maybe<Scalars['Boolean']>
  legacySsoEnforced?: Maybe<Scalars['Boolean']>
}

export type ScreenSsoLoginMethod = {
  __typename?: 'ScreenSsoLoginMethod'
  legacySsoEnabled: Scalars['Boolean']
  legacySsoEnforced: Scalars['Boolean']
}

/** Autogenerated input type of SendInvites */
export type SendInvitesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  invitesAttributes: Array<InviteCreateAttributes>
}

/** Autogenerated return type of SendInvites */
export type SendInvitesPayload = {
  __typename?: 'SendInvitesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  invites: Array<Maybe<Invite>>
  message: Scalars['String']
}

export type Snippet = {
  __typename?: 'Snippet'
  description?: Maybe<Scalars['String']>
  fileContents?: Maybe<Scalars['JSON']>
  id: Scalars['Int']
  language?: Maybe<Scalars['String']>
  slug: Scalars['String']
  title?: Maybe<Scalars['String']>
}

export type Spreadsheet = {
  __typename?: 'Spreadsheet'
  gsheetId: Scalars['String']
  id: Scalars['Int']
}

/** SSO Kind of an organization */
export enum SsoKind {
  Cognito = 'cognito',
  Legacy = 'legacy',
  LegacyCg = 'legacy_cg',
}

export type SsoLoginMethod = {
  __typename?: 'SsoLoginMethod'
  cognitoSsoConfigured: Scalars['Boolean']
  cognitoSsoSamlConfig?: Maybe<CognitoSsoSamlConfig>
  idpEnabled?: Maybe<Scalars['Boolean']>
  idpIssuer?: Maybe<Scalars['String']>
  loginSubdomain?: Maybe<Scalars['String']>
}

export type Team = {
  __typename?: 'Team'
  id: Scalars['String']
  name: Scalars['String']
  organization: Organization
}

export type TestCase = {
  __typename?: 'TestCase'
  argument0?: Maybe<Scalars['String']>
  argument1?: Maybe<Scalars['String']>
  argument2?: Maybe<Scalars['String']>
  arguments?: Maybe<Array<Scalars['String']>>
  id: Scalars['Int']
  returnValue: Scalars['String']
  visible?: Maybe<Scalars['Boolean']>
}

/** Attributes for test case create */
export type TestCaseCreateAttributes = {
  argument0?: Maybe<Scalars['String']>
  argument1?: Maybe<Scalars['String']>
  argument2?: Maybe<Scalars['String']>
  returnValue?: Maybe<Scalars['String']>
  visible?: Maybe<Scalars['Boolean']>
}

/** Data type for test case inputs and return value */
export enum TestCaseDataTypes {
  Integer = 'Integer',
  IntegerArray = 'IntegerArray',
  String = 'String',
  StringArray = 'StringArray',
}

export type TestCasePreview = {
  __typename?: 'TestCasePreview'
  argument0?: Maybe<Scalars['String']>
  argument1?: Maybe<Scalars['String']>
  argument2?: Maybe<Scalars['String']>
  arguments?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['Int']>
  returnValue: Scalars['String']
  visible?: Maybe<Scalars['Boolean']>
}

/** Attributes for test case when used as part of updating a Question. */
export type TestCaseUpdateAttributes = {
  _destroy?: Maybe<Scalars['Boolean']>
  argument0?: Maybe<Scalars['String']>
  argument1?: Maybe<Scalars['String']>
  argument2?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  returnValue?: Maybe<Scalars['String']>
  visible?: Maybe<Scalars['Boolean']>
}

export type TestCasesStarterCode = {
  __typename?: 'TestCasesStarterCode'
  code?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateCustomDatabase */
export type UpdateCustomDatabaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customDatabaseAttributes: CustomDatabaseUpdateAttributes
}

/** Autogenerated return type of UpdateCustomDatabase */
export type UpdateCustomDatabasePayload = {
  __typename?: 'UpdateCustomDatabasePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customDatabase?: Maybe<CustomDatabase>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateCustomFile */
export type UpdateCustomFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customFileAttributes: CustomFileUpdateAttributes
}

/** Autogenerated return type of UpdateCustomFile */
export type UpdateCustomFilePayload = {
  __typename?: 'UpdateCustomFilePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  customFile?: Maybe<CustomFile>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateInterviewNotesPrivacySettingForPad */
export type UpdateInterviewNotesPrivacySettingForPadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  padInterviewNotesPrivacySettingAttributes: PadInterviewNotesPrivacySettingAttributes
}

/** Autogenerated return type of UpdateInterviewNotesPrivacySettingForPad */
export type UpdateInterviewNotesPrivacySettingForPadPayload = {
  __typename?: 'UpdateInterviewNotesPrivacySettingForPadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  pad?: Maybe<Pad>
}

/** Autogenerated input type of UpdateOrganizationGlobal */
export type UpdateOrganizationGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  organizationIdGlobal: Scalars['String']
  organizationName?: Maybe<Scalars['String']>
  trialExpiresAt?: Maybe<Scalars['String']>
}

/** Autogenerated return type of UpdateOrganizationGlobal */
export type UpdateOrganizationGlobalPayload = {
  __typename?: 'UpdateOrganizationGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of UpdateOrganizationMember */
export type UpdateOrganizationMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  userAttributes: OrganizationMemberUpdateAttributes
}

/** Autogenerated return type of UpdateOrganizationMember */
export type UpdateOrganizationMemberPayload = {
  __typename?: 'UpdateOrganizationMemberPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateOrganizationSettings */
export type UpdateOrganizationSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  organizationUpdateAttributes: OrganizationUpdateAttributes
}

/** Autogenerated return type of UpdateOrganizationSettings */
export type UpdateOrganizationSettingsPayload = {
  __typename?: 'UpdateOrganizationSettingsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of UpdatePads */
export type UpdatePadsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  padsAttributes: Array<PadAttributes>
}

/** Autogenerated return type of UpdatePads */
export type UpdatePadsPayload = {
  __typename?: 'UpdatePadsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message: Scalars['String']
  pads: Array<Maybe<Pad>>
}

/** Autogenerated input type of UpdateQuestion */
export type UpdateQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  previewKey?: Maybe<Scalars['Int']>
  questionAttributes: QuestionUpdateAttributes
}

/** Autogenerated return type of UpdateQuestion */
export type UpdateQuestionPayload = {
  __typename?: 'UpdateQuestionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  question?: Maybe<Question>
}

/** Autogenerated input type of UpdateQuotaGlobal */
export type UpdateQuotaGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  organizationIdGlobal: Scalars['String']
  planId?: Maybe<Scalars['Int']>
  trialExpiresAt?: Maybe<Scalars['String']>
}

/** Autogenerated return type of UpdateQuotaGlobal */
export type UpdateQuotaGlobalPayload = {
  __typename?: 'UpdateQuotaGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of UpdateUserGlobal */
export type UpdateUserGlobalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  userId: Scalars['Int']
}

/** Autogenerated return type of UpdateUserGlobal */
export type UpdateUserGlobalPayload = {
  __typename?: 'UpdateUserGlobalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  currentProduct?: Maybe<Application>
  defaultExecution?: Maybe<Scalars['Boolean']>
  defaultLanguage?: Maybe<Scalars['String']>
  defaultPrivacy?: Maybe<Scalars['Boolean']>
  defaultTeamId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  emailOptIn?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  personaRole?: Maybe<UserPersonaRole>
  receivePadEndedSummary?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors: Array<Error>
  message?: Maybe<Scalars['String']>
  user: User
}

/** Period options for Usage */
export enum UsagePeriod {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type User = {
  __typename?: 'User'
  active: Scalars['Boolean']
  activeQuota: Quota
  allowDefaultTeamEdition: Scalars['Boolean']
  allowPadCreation: Scalars['Boolean']
  allowQuestionCreation: Scalars['Boolean']
  allowSandboxAccess: Scalars['Boolean']
  allowTakeHomesAccess: Scalars['Boolean']
  apiKey?: Maybe<Scalars['String']>
  belongsToParentOrganization: Scalars['Boolean']
  canAutocreate: Scalars['Boolean']
  cognitoUserPool?: Maybe<CognitoUserPool>
  cognitoUsername?: Maybe<Scalars['String']>
  corporateEmail?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  currentSignInAt?: Maybe<Scalars['ISO8601DateTime']>
  currentSignInIp?: Maybe<Scalars['String']>
  currentProduct?: Maybe<Application>
  defaultExecution: Scalars['Boolean']
  defaultLanguage: Scalars['String']
  defaultPrivacy?: Maybe<Scalars['Boolean']>
  defaultProduct?: Maybe<Application>
  defaultTeam?: Maybe<Team>
  email: Scalars['String']
  emailOptIn: Scalars['Boolean']
  emailVerified?: Maybe<Scalars['Boolean']>
  flags?: Maybe<Scalars['JSON']>
  id: Scalars['Int']
  integrationsApiKey: Scalars['String']
  isDefaultTeamConfirmed?: Maybe<Scalars['Boolean']>
  isOrganizationCreator?: Maybe<Scalars['Boolean']>
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>
  migratedOnCognito: Scalars['Boolean']
  name: Scalars['String']
  orgMemberModificationAllowed?: Maybe<Scalars['Boolean']>
  organization?: Maybe<Organization>
  organizationOwner?: Maybe<Scalars['Boolean']>
  padsCreatedLast30: Scalars['Int']
  padsCreatedLifetime: Scalars['Int']
  padsStarted: Scalars['Int']
  personaRole: Scalars['String']
  questionsCreated: Scalars['Int']
  quotaOwner: Scalars['Boolean']
  quotaWarning: Scalars['Boolean']
  quotaWarningHuman?: Maybe<Scalars['String']>
  receivePadEndedSummary?: Maybe<Scalars['Boolean']>
  showApiKey: Scalars['Boolean']
  showUpgrade: Scalars['Boolean']
  teams: Array<Team>
}

/** Role options for Users and Invites Type */
export enum UserAndInviteRole {
  Admin = 'admin',
  Member = 'member',
}

/** Type options for Users and Invites Type */
export enum UserAndInviteType {
  Invite = 'Invite',
  User = 'User',
}

export type UserInformation = {
  __typename?: 'UserInformation'
  username?: Maybe<Scalars['String']>
}

/** Valid user persona roles */
export enum UserPersonaRole {
  Candidate = 'candidate',
  Developer = 'developer',
  Other = 'other',
  TalentAcquisition = 'talent_acquisition',
  Unknown = 'unknown',
}

/** Sort options for User */
export enum UserSort {
  EmailAsc = 'emailAsc',
  EmailDesc = 'emailDesc',
  NameAsc = 'nameAsc',
  NameDesc = 'nameDesc',
  OrganizationNameAsc = 'organizationNameAsc',
  OrganizationNameDesc = 'organizationNameDesc',
  OrganizationOwnerAsc = 'organizationOwnerAsc',
  OrganizationOwnerDesc = 'organizationOwnerDesc',
  PadsCreatedLast30Asc = 'padsCreatedLast30Asc',
  PadsCreatedLast30Desc = 'padsCreatedLast30Desc',
  PadsCreatedLifetimeAsc = 'padsCreatedLifetimeAsc',
  PadsCreatedLifetimeDesc = 'padsCreatedLifetimeDesc',
  PadsStartedAsc = 'padsStartedAsc',
  PadsStartedDesc = 'padsStartedDesc',
  QuestionsCreatedAsc = 'questionsCreatedAsc',
  QuestionsCreatedDesc = 'questionsCreatedDesc',
}
