import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { EventEmitter } from '../../../../utils/events/EventEmitter'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { track } from '../../coderpad_analytics'
import { PadAnalyticsEvent } from '../../constants/PadAnalyticsEvent'
import logPadEvent from '../../log_pad_event'
import { enqueueNotif } from '../../reducers/notifications'
import { selectLocalUsername, selectMyUserId, selectOnlineUsers } from '../../selectors'
import SyncHandle from '../../sync_handle'
import { useAudioStream } from '../hooks/useAudioStream'
import { useTranscriber } from '../hooks/useTranscriber'
import { SystemMessage, Transcript, TranscriptEntryKind, TranscriptResult } from '../types'
import { AloneUserAutoStop } from './AloneUserAutoStop'
import { AudioSelection } from './AudioSelection'
import { AutoStop } from './AutoStop'
import { AutoStopWarning } from './AutoStopWarning'
import { Consent } from './Consent'
import { DeclinedTranscription } from './DeclinedTranscription'
import { useDenialNotifications } from './hooks/useDenialNotifications'
import { useStartStopNotifications } from './hooks/useStartStopNotifications'
import { StopConfirmation } from './StopConfirmation'

export const AUTO_END_THRESHOLD = 1000 * 60 * 60 // 1 hour.
export const AUTO_END_WARNING_THRESHOLD = 1000 * 60 * 5 // 5 minutes.
export const ALONE_USER_AUTO_END_THRESHOLD = 1000 * 60 * 5 // 5 minutes.

export enum TranscriberEvents {
  TranscriptionDenied = 'TRANSCRIPTION_DENIED',
  TranscriptionStarted = 'TRANSCRIPTION_STARTED',
  TranscriptionStopped = 'TRANSCRIPTION_STOPPED',
}

export const TranscriberContext = createContext<{
  startTranscription: (autoConsent?: boolean) => void
  stopTranscription: () => void
  isTranscribing: boolean
  setConsentStatus: (hasConsented: boolean) => void
  hasUserConsentedToTranscription: boolean
  transcriberEvents: EventEmitter
  audioDeviceId: string | null
  setAudioDeviceId: (deviceId: string) => void
  isTranscriptionMuted: boolean
  muteTranscription: (isMuted: boolean) => void
  acceptTranscription: () => void
  denyTranscription: () => void
  closedCaptionsEnabled: boolean
  toggleClosedCaptions: () => void
  askForAudioDevices: () => void
  askForTranscriptionConsent: () => void
  setStopConfirmationStatus: (isConfirmingStop: boolean) => void
  resetTranscriptionLimit: () => void
  audioAnalyser: AnalyserNode | null
}>({
  startTranscription: () => {},
  stopTranscription: () => {},
  isTranscribing: false,
  setConsentStatus: () => {},
  hasUserConsentedToTranscription: false,
  transcriberEvents: new EventEmitter(),
  audioDeviceId: null,
  setAudioDeviceId: () => {},
  isTranscriptionMuted: false,
  muteTranscription: () => {},
  acceptTranscription: () => {},
  denyTranscription: () => {},
  closedCaptionsEnabled: false,
  toggleClosedCaptions: () => {},
  askForAudioDevices: () => {},
  askForTranscriptionConsent: () => {},
  setStopConfirmationStatus: () => {},
  resetTranscriptionLimit: () => {},
  audioAnalyser: null,
})

interface TranscriberContextProviderProps {
  children: React.ReactNode
}

export function TranscriberContextProvider({ children }: TranscriberContextProviderProps) {
  const dispatch = useDispatch()

  const { hasPureTranscription, hasTranscriptions, isPlayback } = usePadConfigValues(
    'hasTranscriptions',
    'isOwner',
    'hasPureTranscription',
    'isPlayback'
  )
  const selfUserId = useSelector(selectMyUserId)
  const selfName = useSelector(selectLocalUsername)
  const onlineUsers = useSelector(selectOnlineUsers)
  const showCandidateOverlay = useSelector((state) => state.userState.showCandidateOverlay)

  const [hasUserConsentedToTranscription, setHasUserConsentedToTranscription] = useState(false)
  const [isTranscribing, setIsTranscribing] = useState(false)
  const [audioDeviceId, setAudioDeviceId] = useState('')
  const [isMuted, setIsMuted] = useState(false)
  const [closedCaptionsEnabled, setClosedCaptionsEnabled] = useState(false)
  const [isConfirmingStop, setIsConfirmingStop] = useState(false)
  const [isWarningAutoStop, setIsWarningAutoStop] = useState(false)
  const [isAutoStopped, setIsAutoStopped] = useState(false)
  const [isTranscriptionDenialOpen, setIsTranscriptionDenialOpen] = useState(false)
  const [isAudioSelectionOpen, setIsAudioSelectionOpen] = useState(false)
  const [aloneUserAutoStop, setAloneUserAutoStop] = useState(false)
  const events = useRef(new EventEmitter())
  const hasStoppedPersonalTranscription = useRef(false)

  const onInterimResult = useCallback(
    (id: string, result: TranscriptResult) => {
      console.log('Interim result:', result)
      if (result != null && result.text.length > 0) {
        SyncHandle().set(`transcript/${id}`, {
          kind: TranscriptEntryKind.Transcript,
          transcripts: [result.text || ''],
          preferredTranscript: 0,
          userId: selfUserId,
          timestamp: Date.now(),
          duration: result.duration,
          isFinal: false,
        } as Transcript)
      }
    },
    [selfUserId]
  )

  const onFinalResult = useCallback(
    (id: string, result: TranscriptResult) => {
      console.log('Final result:', result)
      if (result != null && result.text?.length > 0) {
        SyncHandle().set(`transcript/${id}`, {
          kind: TranscriptEntryKind.Transcript,
          transcripts: [result.text || ''],
          preferredTranscript: 0,
          userId: selfUserId,
          timestamp: Date.now(),
          duration: result.duration,
          isFinal: true,
        } as Transcript)
      }
    },
    [selfUserId]
  )

  const transcriber = useTranscriber({
    transcriberUrl: window.CoderPad.TRANSCRIPT_SERVICE_URL,
    onInterimResult,
    onFinalResult,
    hasConsentedToTranscription: hasUserConsentedToTranscription,
  })

  const onAudioData = useCallback(
    (data: Int16Array) => {
      if (isMuted) return
      transcriber.send(data)
    },
    [transcriber, isMuted]
  )

  const stream = useAudioStream(audioDeviceId, onAudioData)

  useEffect(() => {
    if (stream.audioContext && transcriber) {
      if (stream.sampleRate !== transcriber.sampleRate) {
        transcriber.setSampleRate(stream.sampleRate)
      }
      if (stream.channelCount !== transcriber.channelCount) {
        transcriber.setChannelCount(stream.channelCount)
      }
    }
  }, [stream.audioContext, stream.sampleRate, stream.channelCount, transcriber])

  const startTranscription = useCallback(
    (autoConsent = false) => {
      if (
        !stream.isListening &&
        hasTranscriptions &&
        (hasUserConsentedToTranscription || autoConsent) &&
        !isTranscribing
      ) {
        console.log('audio stream starting')
        stream.start()
        setIsTranscribing(true)
        SyncHandle().set(`transcriptionStartedAt`, SyncHandle().TIMESTAMP_SENTINEL)
        SyncHandle().set(
          `userTranscriptionStats/startedAt/${selfUserId}`,
          SyncHandle().TIMESTAMP_SENTINEL
        )
        setIsMuted(false) // Unmute when starting transcription.
        if (autoConsent) {
          setHasUserConsentedToTranscription(true)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasTranscriptions,
      hasUserConsentedToTranscription,
      stream.start,
      stream.isListening,
      isTranscribing,
      selfUserId,
    ]
  )

  const stopTranscription = useCallback(
    () => {
      if (stream.isListening) {
        console.log('audio stream stopping')
        stream.stop()
        setIsTranscribing(false)
        setHasUserConsentedToTranscription(false)
        hasStoppedPersonalTranscription.current = true
        SyncHandle().set(
          `userTranscriptionStats/stoppedAt/${selfUserId}`,
          SyncHandle().TIMESTAMP_SENTINEL
        )
      }
    },
    // eslint-disable-line react-hooks/exhaustive-deps
    [stream.isListening, stream.stop, selfUserId]
  )

  const resetTranscriptionLimit = useCallback(() => {
    SyncHandle().set(`transcriptionStartedAt`, SyncHandle().TIMESTAMP_SENTINEL)
  }, [])

  // Listen for updates to `transcriptionStartedAt`.
  const [startedAt, setStartedAt] = useState(0)
  useEffect(() => {
    const watchCB = SyncHandle().watch<number>('transcriptionStartedAt', (startTime) => {
      setStartedAt(startTime)
    })

    return () => {
      SyncHandle().off('transcriptionStartedAt', watchCB)
    }
  }, [])

  const askForTranscriptionConsent = useCallback(() => {
    if (!hasUserConsentedToTranscription) {
      setIsObtainingConsent(true)
    }
  }, [hasUserConsentedToTranscription])

  // There are a few things that need to happen when `transcriptionStartedAt` changes:
  // 1. Set up auto-end and auto-end warning timeouts.
  // 2. Prompt user to start transcription if not transcribing but within the auto-end threshold.
  useEffect(() => {
    let warningTimeout: ReturnType<typeof setTimeout> | null = null
    let autoEndTimeout: ReturnType<typeof setTimeout> | null = null

    // Only pads with pure transcription should setup auto-end and notifications.
    if (hasPureTranscription && !isPlayback) {
      const timeLeft = startedAt != null ? startedAt + AUTO_END_THRESHOLD - Date.now() : 0

      // If there is time left within the auto-end threshold, we should be transcribing.
      const shouldBeTranscribing =
        timeLeft < AUTO_END_THRESHOLD &&
        timeLeft > 0 && // There is positive time left .
        !isTranscribing && // Not already transcribing.
        !hasStoppedPersonalTranscription.current

      if (shouldBeTranscribing) {
        askForTranscriptionConsent()
      } else if (isTranscribing && timeLeft > 0) {
        if (timeLeft - AUTO_END_WARNING_THRESHOLD < 0) {
          setIsWarningAutoStop(true)
        } else {
          setIsWarningAutoStop(false)
          warningTimeout = setTimeout(() => {
            if (isTranscribing) {
              setIsWarningAutoStop(true)
            }
          }, timeLeft - AUTO_END_WARNING_THRESHOLD)
        }
        autoEndTimeout = setTimeout(() => {
          stopTranscription()
          setIsWarningAutoStop(false)
          setIsAutoStopped(true)
        }, timeLeft)
      } else if (isTranscribing && timeLeft <= 0) {
        stopTranscription()
      }
    }

    return () => {
      if (warningTimeout != null) {
        clearTimeout(warningTimeout)
      }
      if (autoEndTimeout != null) {
        clearTimeout(autoEndTimeout)
      }
    }
  }, [
    dispatch,
    hasPureTranscription,
    isTranscribing,
    startedAt,
    stopTranscription,
    isPlayback,
    askForTranscriptionConsent,
  ])

  const [isObtainingConsent, setIsObtainingConsent] = useState(false)

  useEffect(() => {
    if (isTranscribing) {
      setIsWarningAutoStop(false)
      setIsAutoStopped(false)
    } else {
      setIsWarningAutoStop(false)
    }
  }, [dispatch, isTranscribing])

  const denyTranscription = useCallback(() => {
    SyncHandle().set(`transcript/${v4()}`, {
      kind: TranscriptEntryKind.SystemMessage,
      message: `${selfName} joined the call and declined recording.`,
      timestamp: SyncHandle().TIMESTAMP_SENTINEL,
    } as SystemMessage)
    SyncHandle().set(`usersDeniedTranscription/${selfUserId}`, Date.now())
    track(PadAnalyticsEvent.TranscriptionUserDisagreed, {
      username: selfName,
    })
    setHasUserConsentedToTranscription(false)
  }, [selfName, selfUserId, setHasUserConsentedToTranscription])

  const acceptTranscription = useCallback(() => {
    SyncHandle().set(`transcript/${v4()}`, {
      kind: TranscriptEntryKind.SystemMessage,
      message: `${selfName} joined the call and agreed to recording.`,
      timestamp: SyncHandle().TIMESTAMP_SENTINEL,
    } as SystemMessage)
    SyncHandle().set(`usersDeniedTranscription/${selfUserId}`, 0)
    setHasUserConsentedToTranscription(true)
    logPadEvent('agreed_to_transcription', { username: selfName })
    track(PadAnalyticsEvent.TranscriptionUserAgreed, {
      username: selfName,
    })
  }, [selfName, selfUserId, setHasUserConsentedToTranscription])

  // Using a ref for this because we don't want the excessive number of updates to re-trigger this effect all the time.
  // It is OK to use a ref because we really just care about the number of online users at the time
  // `usersDeniedTranscription` updates in Firebase.
  const onlineUsersRef = useRef<Record<string, any>>({})
  const [numOnlineUsers, setNumOnlineUsers] = useState(0)
  useEffect(() => {
    for (const user of onlineUsers) {
      onlineUsersRef.current[String(user.id)] = user
    }
    setNumOnlineUsers(onlineUsers.length)
  }, [onlineUsers])

  // Watch for the transition from 1 user to many users to auto-start transcription.
  const prevNumOnlineUsers = useRef(numOnlineUsers)
  useEffect(() => {
    if (
      hasPureTranscription &&
      !isTranscribing &&
      numOnlineUsers > 1 &&
      prevNumOnlineUsers.current === 1 &&
      !hasUserConsentedToTranscription
    ) {
      askForTranscriptionConsent()
    }
    prevNumOnlineUsers.current = numOnlineUsers

    let aloneUserAutoEndTimeout: ReturnType<typeof setTimeout> | null = null
    if (isTranscribing && numOnlineUsers === 1) {
      aloneUserAutoEndTimeout = setTimeout(() => {
        setAloneUserAutoStop(true)
        setStartedAt(0)
        stopTranscription()
      }, ALONE_USER_AUTO_END_THRESHOLD)
    }

    return () => {
      if (aloneUserAutoEndTimeout) {
        clearTimeout(aloneUserAutoEndTimeout)
      }
    }
  }, [
    numOnlineUsers,
    isTranscribing,
    hasPureTranscription,
    stopTranscription,
    askForTranscriptionConsent,
  ])

  const denialCallback = useCallback((userId: string) => {
    events.current.emit(TranscriberEvents.TranscriptionDenied, userId)
    setIsTranscriptionDenialOpen(true)
  }, [])
  useDenialNotifications(onlineUsersRef.current, isTranscribing, denialCallback)

  // Effect to clean up the user's transcription start/stop values when they are not actively transcribing.
  // This is important to keep these values clean so that the below effects/watchers aren't over-triggering.
  useEffect(() => {
    if (!isTranscribing) {
      SyncHandle().set(`userTranscriptionStats/startedAt/${selfUserId}`, 0)
      SyncHandle().set(`userTranscriptionStats/stoppedAt/${selfUserId}`, 0)
    }
  }, [selfUserId, isTranscribing])

  const onUserStart = useCallback(
    (userId) => {
      events.current.emit(TranscriberEvents.TranscriptionStarted, userId)
      dispatch(
        enqueueNotif({
          autoDismissMs: 5000,
          message: `${onlineUsersRef.current[userId]?.name ?? 'Someone'} has started transcribing`,
          key: `transcription_user_status_${userId}`,
          variant: 'info',
        })
      )
    },
    [dispatch]
  )
  useStartStopNotifications('start', onlineUsersRef.current, isTranscribing, onUserStart)

  const onUserStop = useCallback(
    (userId) => {
      events.current.emit(TranscriberEvents.TranscriptionStopped, userId)
      dispatch(
        enqueueNotif({
          autoDismissMs: 5000,
          message: `${onlineUsersRef.current[userId]?.name ?? 'Someone'} has stopped transcribing`,
          key: `transcription_user_status_${userId}`,
          variant: 'info',
        })
      )
    },
    [dispatch]
  )
  useStartStopNotifications('stop', onlineUsersRef.current, isTranscribing, onUserStop)

  const muteTranscription = useCallback(
    (isMuted: boolean) => {
      if (isTranscribing) {
        setIsMuted(isMuted)
        if (isMuted) {
          SyncHandle().set(
            `userTranscriptionStats/stoppedAt/${selfUserId}`,
            SyncHandle().TIMESTAMP_SENTINEL
          )
        } else {
          SyncHandle().set(
            `userTranscriptionStats/startedAt/${selfUserId}`,
            SyncHandle().TIMESTAMP_SENTINEL
          )
        }
      }
    },
    [selfUserId, isTranscribing]
  )

  const videoCallStatus = useSelector((state) => state.call.status)

  return (
    <TranscriberContext.Provider
      value={{
        startTranscription,
        stopTranscription,
        isTranscribing,
        setConsentStatus: setHasUserConsentedToTranscription,
        hasUserConsentedToTranscription,
        transcriberEvents: events.current,
        setAudioDeviceId,
        audioDeviceId,
        isTranscriptionMuted: isMuted,
        muteTranscription,
        acceptTranscription,
        denyTranscription,
        closedCaptionsEnabled,
        toggleClosedCaptions: () => setClosedCaptionsEnabled(!closedCaptionsEnabled),
        askForAudioDevices: () => setIsAudioSelectionOpen(true),
        askForTranscriptionConsent,
        setStopConfirmationStatus: setIsConfirmingStop,
        resetTranscriptionLimit,
        audioAnalyser: stream.analyser,
      }}
    >
      {children}
      {!showCandidateOverlay && videoCallStatus === 'no_call' ? (
        <>
          <AudioSelection
            isOpen={isAudioSelectionOpen}
            requestClose={() => setIsAudioSelectionOpen(false)}
          />
          <Consent isOpen={isObtainingConsent} requestClose={() => setIsObtainingConsent(false)} />
          <StopConfirmation
            isOpen={isTranscribing && isConfirmingStop}
            requestClose={() => setIsConfirmingStop(false)}
          />
          <AutoStopWarning
            isOpen={isTranscribing && isWarningAutoStop}
            requestClose={() => setIsWarningAutoStop(false)}
          />
          <AutoStop isOpen={isAutoStopped} requestClose={() => setIsAutoStopped(false)} />
          <AloneUserAutoStop
            isOpen={aloneUserAutoStop}
            requestClose={() => setAloneUserAutoStop(false)}
          />
        </>
      ) : null}
      <DeclinedTranscription
        isOpen={isTranscriptionDenialOpen}
        requestClose={() => setIsTranscriptionDenialOpen(false)}
      />
    </TranscriberContext.Provider>
  )
}

export function useTranscriberContext(onTranscriptionDenial?: (firebaseAuthorId: string) => void) {
  const context = React.useContext(TranscriberContext)

  useEffect(() => {
    if (context && onTranscriptionDenial) {
      context.transcriberEvents.on(TranscriberEvents.TranscriptionDenied, onTranscriptionDenial)
    }

    return () => {
      if (onTranscriptionDenial) {
        context.transcriberEvents.remove(
          TranscriberEvents.TranscriptionDenied,
          onTranscriptionDenial
        )
      }
    }
  }, [context, onTranscriptionDenial])

  return context
}
