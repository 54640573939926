import React, { useCallback, useEffect } from 'react'

export enum MessageRelayType {
  Log = 'zoomRoomLog',
  Error = 'zoomRoomError',

  Id = 'zoomRoomId',

  Connected = 'zoomRoomConnected',
  Disconnected = 'zoomRoomDisconnected',

  SelfJoined = 'zoomRoomSelfJoined',
  SelfLeft = 'zoomRoomSelfLeft',

  SelfAudioMuted = 'zoomRoomSelfAudioMuted',
  SelfAudioUnmuted = 'zoomRoomSelfAudioUnmuted',

  SelfVideoMuted = 'zoomRoomSelfVideoMuted',
  SelfVideoUnmuted = 'zoomRoomSelfVideoUnmuted',
}

export type MessageRelayMessage =
  | {
      type:
        | MessageRelayType.SelfJoined
        | MessageRelayType.SelfLeft
        | MessageRelayType
        | MessageRelayType.SelfAudioMuted
        | MessageRelayType.SelfAudioUnmuted
        | MessageRelayType.SelfVideoMuted
        | MessageRelayType.SelfVideoUnmuted
    }
  | {
      type: MessageRelayType.Error
      error: Error
    }
  | {
      type: MessageRelayType.Log
      message: string
    }
  | {
      type: MessageRelayType.Connected | MessageRelayType.Disconnected
      username: string
    }
  | {
      type: MessageRelayType.Id
      id: string
    }

interface MessageRelayContextValue {
  sendMessage: (message: MessageRelayMessage) => void
  onMessage(message: MessageRelayType, callback: (message: MessageEvent) => void): () => void
}

const MessageRelayContext = React.createContext<MessageRelayContextValue | null>(null)

export const MessageRelayProvider: React.FC = ({ children }) => {
  /**
   * Posts a strongly typed message to the parent frame
   */
  const sendMessage = (message: MessageRelayMessage) => {
    window.parent.postMessage(message, '*')
  }

  /**
   * Logs messages from the parent frame to the console
   */
  const handleMessage = useCallback((event: MessageEvent) => {
    const message = event.data as MessageRelayMessage
    if (Object.values(MessageRelayType).includes(message.type)) {
      console.log(message)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  const onMessage = useCallback(
    (type: MessageRelayType, callback: (message: MessageEvent) => void) => {
      const listener = (event: MessageEvent) => {
        if (event.data.type === type) {
          callback(event)
        }
      }

      window.addEventListener('message', listener)

      return () => {
        window.removeEventListener('message', listener)
      }
    },
    []
  )

  return (
    <MessageRelayContext.Provider value={{ sendMessage, onMessage }}>
      {children}
    </MessageRelayContext.Provider>
  )
}

export const useMessageRelay = (): MessageRelayContextValue => {
  const context = React.useContext(MessageRelayContext)
  if (!context) {
    throw new Error('useMessageRelay must be used within a MessageRelayProvider')
  }
  return context
}
