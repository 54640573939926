import { FormControl, InputLabel, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'classnames'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import React, { useCallback } from 'react'

import {
  LanguageAndProjectTemplateListItem,
  useLanguageAndProjectTemplateList,
} from '../../../../../graphql/hooks/useLanguageAndProjectTemplateList/useLanguageAndProjectTemplateList'
import * as queryStates from '../../../../../graphql/queryStates'
import { useAnalyticsZone } from '../../../../dashboard/components/AnalyticsZone/AnalyticsZone'
import { SearchInput } from '../../../../dashboard/components/SearchInput/SearchInput'
import { PadAnalyticsEvent } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  searchFilters: {
    display: 'flex',
    flexDirection: 'row',
    '&.shrink': {
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
  },
  searchFilter: {
    flex: 1,
    marginRight: theme.spacing(1),
    '&:last-of-type': {
      marginRight: 0,
    },

    '&.shrink': {
      [theme.breakpoints.down('lg')]: {
        marginRight: 0,
        marginBottom: theme.spacing(1.5),
      },
    },
  },
}))

export const QuestionFilters: React.FC<{
  onTextChange: (val: string) => void
  onLangChange: (lang: string | null) => void
  textSearchVal: string
  langSearchVal: string
  shrink?: boolean
}> = ({ onTextChange, onLangChange, textSearchVal, langSearchVal, shrink }) => {
  const styles = useStyles()
  const padSlug = usePadConfigValue('slug')
  const { list, status } = useLanguageAndProjectTemplateList({
    includeEmptyOption: false,
    padSlug: padSlug,
  })
  const { trackEvent } = useAnalyticsZone()

  const handleTextChange = useCallback(
    (val: string) => {
      onTextChange(val)
      trackEvent(PadAnalyticsEvent.QuestionBankFilterChanged, { filter: 'text', value: val })
    },
    [trackEvent, onTextChange]
  )

  const handleLangChange = useCallback(
    (val: string | null) => {
      onLangChange(val)
      trackEvent(PadAnalyticsEvent.QuestionBankFilterChanged, { filter: 'language', value: val })
    },
    [trackEvent, onLangChange]
  )

  return (
    <div className={clsx(styles.searchFilters, { shrink })}>
      <div className={clsx(styles.searchFilter, { shrink })}>
        <SearchInput
          id="question-environments-text-search"
          onValueChange={handleTextChange}
          label="Search"
          type="text"
          debounce={300}
          variant="outlined"
          fullWidth
          margin="dense"
          defaultValue={textSearchVal}
        />
      </div>
      <div className={clsx(styles.searchFilter, { shrink })}>
        <FormControl variant="outlined" fullWidth margin="dense">
          <InputLabel id="environment-question-menu-language-select-label">Language</InputLabel>
          <Select
            value={langSearchVal}
            labelId="environment-question-menu-language-select-label"
            aria-label="Filter by language"
            id="environment-question-menu-language-select"
            disabled={queryStates.isLoadingState(status)}
            onChange={(e) =>
              handleLangChange(e.target.value === 'any' ? null : (e.target.value as string))
            }
            label="Language"
            sx={{
              paddingTop: '2px',
              paddingBottom: '2px',
            }}
            native
          >
            <option value="any">All Languages</option>
            {list.map((item: LanguageAndProjectTemplateListItem) => {
              return (
                // Using the native select for now, since the MUI select popover closes the whole env menu.
                // Something to do with the click away listener.
                // <MenuItem key={item.value} value={item.value}>
                //   {item.label}
                // </MenuItem>
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              )
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}
