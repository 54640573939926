import CreateIcon from '@mui/icons-material/Create'
import { Box } from '@mui/material'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { ErrorBoundary } from '../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../dashboard/components/GenericErrorView/GenericErrorView'
import Button from './button'
import FocusTimeButton from './focus_time_button'
import FocusTimeCountdown from './focus_time_countdown'
import LanguageSelector from './language_selector'
import MultiEditor from './multi_editor'
import padConfig from './pad_config'
import ResizeDetector from './resize_detector'
import RunButton from './Run/RunButton'

class _CodePane extends React.PureComponent {
  static propTypes = {
    darkColorScheme: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    onLanguageChange: PropTypes.func.isRequired,
    onPackageChange: PropTypes.func.isRequired,
    width: PropTypes.string, // CSS string
    takeHome: PropTypes.bool, // CSS string
    isSandboxFeatureShowcase: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      shouldCollapseActions: false,
    }
  }

  render() {
    const { focusTimeEnabled, focusTimeStartedAt, isSandboxFeatureShowcase } = this.props

    // Determine if the FT button should be shown.
    const focusTimeAvailable =
      isSandboxFeatureShowcase ||
      // Flags to check if FT is supported.
      (!padConfig.isSandbox &&
        padConfig.focusTimeSupported &&
        !padConfig.takeHome &&
        (!focusTimeEnabled || padConfig.isOwner) &&
        // Check if FT has been used
        !(focusTimeStartedAt === undefined || !!focusTimeStartedAt))

    // Determine if DM is available.
    const drawingModeAvailable = !padConfig.iioLayout && !padConfig.takeHome

    // Determine the threshold for whether the action buttons should collapse their text.
    const actionCollapseThreshold = focusTimeAvailable && drawingModeAvailable ? 430 : 290

    return (
      <div
        className={classNames({
          CodePane: true,
          'CodePane--isFullWidth': !this.props.width,
          'CodePane--isSmall': this.state.shouldCollapseActions,
        })}
        style={this.props.width && { width: this.props.width }}
      >
        <ErrorBoundary
          extraTags={{ locality: 'CodePane' }}
          fallback={(err) => (
            <GenericErrorView
              error={err}
              message="An unexpected error occurred in the editor. Please try refreshing the page to fix the problem."
            />
          )}
        >
          {!padConfig.isPlayback && (
            <div
              className={classNames({
                'CodePane-header': true,
                'CodePane-headerFocusTime': focusTimeEnabled && !padConfig.isOwner,
              })}
            >
              <div className="CodePane-header-actionGroup">
                <ResizeDetector
                  ignoreHeight={true}
                  onResize={(paneWidth) =>
                    this.setState({ shouldCollapseActions: paneWidth < actionCollapseThreshold })
                  }
                />
                {!padConfig.invisible && (
                  <Box sx={{ display: 'inline-block', margin: '0 10px 6px 0', minWidth: '112px' }}>
                    <RunButton />
                  </Box>
                )}
                {/*
                  Wrap the FT and DM buttons in a div to prevent them from wrapping separately. We want them to wrap
                  together as a group underneath the Run button.
                */}
                <div className="CodePane-header-actionGroup-secondary">
                  {drawingModeAvailable ? (
                    <Button
                      onClick={this.props.handleOpenDrawingMode}
                      className="btn-inverse CodePane-drawButton"
                      type="regular"
                      darkBg={this.props.darkColorScheme}
                      tooltip={{
                        trigger: 'hover manual',
                        placement: 'bottom',
                        title: 'Open a collaborative drawing window.',
                      }}
                    >
                      <span className="CodePane-drawButton-text">Drawing Mode</span>
                      <CreateIcon className="CodePane-drawButton-icon" />
                    </Button>
                  ) : null}
                  {focusTimeAvailable ? (
                    <FocusTimeButton darkBg={this.props.darkColorScheme} />
                  ) : null}
                </div>
              </div>

              {focusTimeEnabled && !padConfig.isOwner && (
                <div className={'CodePane-headerFocusTimeWrapper'}>
                  <FocusTimeCountdown startedAt={focusTimeStartedAt} />
                </div>
              )}

              <div className="CodePane-header-configGroup">
                <LanguageSelector
                  language={this.props.language}
                  onLanguageChange={this.props.onLanguageChange}
                  onPackageChange={this.props.onPackageChange}
                />
              </div>
            </div>
          )}

          <MultiEditor />
        </ErrorBoundary>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { darkColorScheme } = state.editorSettings
  const {
    focusTimeEnabled,
    focusTimeStartedAt,
    language,
    title,
    takeHome,
    sandboxView,
  } = state.padSettings

  return {
    darkColorScheme,
    focusTimeEnabled,
    focusTimeStartedAt,
    language,
    title,
    takeHome,
    isSandboxFeatureShowcase: padConfig.isSandbox && !!sandboxView,
  }
}

const mapDispatchToProps = {
  onLanguageChange(newLang, userId, oldLang) {
    return {
      type: 'pad_setting_changed',
      key: 'language',
      value: newLang,
      previousValue: oldLang,
      userId,
      _analytics: {
        name: 'Language Changed',
        params: { to_language: newLang },
      },
    }
  },
  onPackageChange({ language, packageName, databaseLanguage }) {
    return {
      type: 'package_changed',
      language,
      packageName,
      databaseLanguage,
      _analytics: {
        name: 'Package Changed',
        params: { to_package: packageName },
      },
    }
  },
}

const CodePane = connect(mapStateToProps, mapDispatchToProps)(_CodePane)

export default CodePane
