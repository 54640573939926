import { Box, Stack, styled, ThemeProvider } from '@mui/material'
import classNames from 'classnames'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { createRef, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ThemeMode, useTheme } from '../../../theme/theme'
import { useAnalytics } from '../../../utils'
import { BeamerButton } from '../BeamerButton'
import CreatePadFromSandboxButton from '../components/CreatePadFromSandboxButton/CreatePadFromSandboxButton'
import { PadAnalyticsEvent } from '../constants'
import DuplicatePadButton from '../PadDuplication/DuplicatePadButton'
import { PadFeedbackButton } from '../PadFeedbackButton'
import PadFooterRight from '../PadFooterRight'
import { PadInvitesButton } from '../PadInvites/PadInvitesButton'
import { PadSettings } from '../PadSettings/PadSettings'
import { PadSettingsButton } from '../PadSettings/PadSettingsButton'
import {
  selectIsSandboxShowcase,
  selectIsSandboxShowcaseInterviewer,
  selectOnlineUsers,
  selectPadSettings,
} from '../selectors'
import TakeHomePanel from '../take_home_panel'
import { TakeHomeSubmitButton } from '../TakeHomeSubmitButton'
import { humanizeMinutesToHours } from '../time_helper'
import { CallButton } from '../video/CallButton'
import { EndInterviewButton } from './EndInterviewButton'
import { TranscriptionControls } from './TranscriptionControls/TranscriptionControls'
import { TrackedUserBanner } from './UserTracking/TrackedUserBanner'
import { UserList } from './UserTracking/UserList'

const Footer = styled('div')(({ theme }) => ({
  background: theme.palette.editor.footer,
}))

/**
 * Pad footer for an active pad.
 * See also pad_footer_playback
 * TODO: move to packs/main/Footer
 */

export const PadFooter: React.FC = () => {
  const { track } = useAnalytics()
  const { focusTimeEnabled, takeHome, takeHomeTimeLimit } = useSelector(selectPadSettings)

  const isSandboxShowcaseInterviewer = useSelector(selectIsSandboxShowcaseInterviewer)
  const isSandboxShowcase = useSelector(selectIsSandboxShowcase)
  const videoCallStatus = useSelector((state) => state.call.status)

  const {
    invisible,
    isOwner,
    hasEnvironments,
    isSandbox,
    uiType,
    hasPureTranscription,
  } = usePadConfigValues(
    'invisible',
    'isOwner',
    'hasEnvironments',
    'isSandbox',
    'uiType',
    'hasPureTranscription'
  )
  const takeHomeTimeLimitHuman = humanizeMinutesToHours(takeHomeTimeLimit)
  const takeHomeSubmitButtonVisible = !isOwner && takeHome
  const footerRef = createRef<HTMLDivElement>()

  const trackedUserId = useSelector((state) => state.userState.trackedUserId)
  const users = useSelector(selectOnlineUsers)

  const trackedUser = users.find((u) => u.id === trackedUserId)
  const [isPadSettingsOpen, setIsPadSettingsOpen] = useState(false)

  const handlePadSettingsButtonClick = useCallback(() => {
    setIsPadSettingsOpen((prevIsPadSettingsOpen) => {
      if (!prevIsPadSettingsOpen) {
        track(PadAnalyticsEvent.PadSettingsOpened)
      }

      return !prevIsPadSettingsOpen
    })
  }, [track])

  const handlePadSettingsClose = useCallback(() => {
    setIsPadSettingsOpen(false)
  }, [])

  const isPadSettingsButtonVisible = useMemo(() => uiType === 'drawing_only' || !hasEnvironments, [
    hasEnvironments,
    uiType,
  ])

  const focusTimeTheme = useTheme(ThemeMode.Light)
  const isFocusTimeThemedFooter = focusTimeEnabled && !isOwner

  return (
    // When Focus Time is on, the footer background color becomes such that the buttons and inputs become unreadable
    // in dark mode. We force the footer to be in light mode when Focus Time is on so that the buttons and inputs
    // will always be legible.
    <ThemeProvider theme={(theme) => (isFocusTimeThemedFooter ? focusTimeTheme : theme)}>
      <Footer
        className={classNames({
          PadFooter: true,
          'PadFooter-focusTime': isFocusTimeThemedFooter,
        })}
        ref={footerRef}
      >
        {trackedUser && (
          <TrackedUserBanner color={trackedUser.color}>
            Following {trackedUser.name}
          </TrackedUserBanner>
        )}
        <Stack
          direction="row"
          spacing={1}
          ml={3}
          mr={2}
          alignItems="center"
          flexGrow={1}
          overflow="hidden"
        >
          <CreatePadFromSandboxButton />
          {((!isSandbox && isOwner) || isSandboxShowcaseInterviewer) && <PadInvitesButton />}
          {(!isSandbox && !takeHome) || isSandboxShowcase ? <CallButton /> : null}
          {!isSandbox && hasPureTranscription && videoCallStatus === 'no_call' ? (
            <TranscriptionControls />
          ) : null}
          {(!takeHome && !isSandbox) || isSandboxShowcase ? <UserList /> : null}
          {takeHome && <TakeHomePanel />}
        </Stack>
        <Stack direction="row" spacing={2} mr={2} alignItems="center">
          <PadFooterRight footerRef={footerRef}>
            <BeamerButton />
            {!invisible && isOwner && !hasEnvironments && window.CoderPad.FLAGS.showDuplicatePad && (
              <Box mt="-10px" mr="10px">
                <DuplicatePadButton />
              </Box>
            )}
            {isPadSettingsButtonVisible && (
              <Box mt="-10px" mr="10px">
                <PadSettingsButton onClick={handlePadSettingsButtonClick} opensFrom="bottomRight" />
              </Box>
            )}
            <PadFeedbackButton />
          </PadFooterRight>
          {((!isSandbox && isOwner) || isSandboxShowcaseInterviewer) &&
            (takeHome ? (
              <span
                className="PadFooter-takeHomeLabel"
                data-toggle="modal"
                style={{ whiteSpace: 'nowrap' }}
              >
                Take-Home {takeHomeTimeLimitHuman ? `(${takeHomeTimeLimitHuman})` : ''}
              </span>
            ) : (
              <EndInterviewButton />
            ))}
          {takeHomeSubmitButtonVisible && <TakeHomeSubmitButton />}
        </Stack>
        <Box sx={{ bottom: 49, position: 'absolute', right: 0, zIndex: 12 }}>
          <PadSettings direction="up" isOpen={isPadSettingsOpen} onClose={handlePadSettingsClose} />
        </Box>
      </Footer>
    </ThemeProvider>
  )
}

export default PadFooter
