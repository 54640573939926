import { Dialog } from '@codinpad/shared-components/components/dialog/Dialog'
import { Box, Button, DialogContentText } from '@mui/material'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useFetch } from '../../../utils/fetch/useFetch'
import { pluralize } from '../../../utils/i18n'
import { usePadConfigValue } from '../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../sync_handle'
import { TwilioCaller } from './call_root'

interface CallInviteScreenProps {
  callParticipants: TwilioCaller[]
  onDecline: () => void
}

export const CallInviteScreen: React.FC<CallInviteScreenProps> = ({
  callParticipants,
  onDecline: handleDecline,
}) => {
  const padSlug = usePadConfigValue('slug')
  const fetch = useFetch()
  const dispatch = useDispatch()
  const onAccept = useCallback(() => {
    dispatch({
      type: 'invite_accepted',
      _analytics: {
        name: 'Call Setup',
        params: {
          from_invite: true,
          from_reconnect: false,
        },
      },
    })
  }, [dispatch])
  const onDecline = useCallback(() => {
    handleDecline()
    dispatch({ type: 'invite_declined', _analytics: { name: 'Call Invite Declined' } })
  }, [dispatch, handleDecline])

  const [waitingMsg, setWaitingMsg] = useState<ReactNode | null>(null)

  useEffect(() => {
    SyncHandle().get('twilioRoomId', async (twilioRoomId) => {
      if (twilioRoomId) {
        const rawInfo = await fetch(`/${padSlug}/room_info?room_id=${twilioRoomId}`, {})
        const info = await rawInfo.json()
        if (info?.participants?.length) {
          SyncHandle().get('users', (users: Record<number, Record<string, string>>) => {
            if (users != null) {
              const presentUsers = []
              for (const fbAuthId of info.participants) {
                const user = users[fbAuthId]
                if (user) {
                  presentUsers.push(user)
                }
              }
              const userWithName = presentUsers.find((u) => u.name)

              const participantName = userWithName?.name || 'Someone'
              let sentence = ' is '
              if (info.participants.length > 1) {
                sentence = ` and ${info.participants.length - 1} ${pluralize(
                  'other',
                  info.participants.length - 1
                )} are `
              }
              sentence += 'waiting for you.'
              setWaitingMsg(
                <Box pb={2} component="span" display="block">
                  <strong>{participantName}</strong>
                  {sentence}
                </Box>
              )
            }
          })
        }
      }
    })
  }, [fetch, padSlug])

  return (
    <Dialog
      open={true}
      dialogTitle="You've been invited to a call"
      actions={[
        <Button key="decline" onClick={onDecline} variant="outlined" color="inherit">
          Decline
        </Button>,
        <Button key="accept" onClick={onAccept} variant="contained" color="primary">
          Accept
        </Button>,
      ]}
      content={
        <DialogContentText>
          {waitingMsg}
          Your browser may prompt for microphone and webcam permissions.
        </DialogContentText>
      }
    ></Dialog>
  )
}
