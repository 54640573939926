import { Button, ButtonGroup } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useAnalyticsZone } from 'packs/dashboard/components/AnalyticsZone/AnalyticsZone'
import { PadAnalyticsEvent } from 'packs/main/constants'
import React, { useCallback } from 'react'

const ToggleButton = withStyles((theme) => {
  const isDark = theme.palette.mode === 'dark'
  return {
    root: {
      color: isDark ? '#fff' : '#2D72CC',
      backgroundColor: theme.palette.editor?.sidebar.background,
      borderColor: isDark ? theme.palette.editor?.sidebar.active : '#2D72CC80',
      '&:hover': {
        borderColor: isDark ? theme.palette.editor?.sidebar.active : '#2D72CC',
      },
      '&.isSelected': {
        backgroundColor: theme.palette.editor?.sidebar.active,
        color: isDark ? '#fff' : '#00000080',
        borderColor: theme.palette.editor?.sidebar.active,
      },
    },
  }
})(Button)

export const QuestionClassificationToggle: React.FC<{
  onToggle: (isExample: boolean, userOnly: boolean) => void
  example: boolean
  userOnly: boolean
}> = ({ example, onToggle, userOnly }) => {
  const { trackEvent } = useAnalyticsZone()

  const handleToggle = useCallback(
    (type: string) => {
      if (type === 'organization') {
        onToggle(false, false)
      } else if (type === 'myContent') {
        onToggle(false, true)
      } else {
        onToggle(true, userOnly)
      }
      trackEvent(PadAnalyticsEvent.QuestionBankQuestionTypeChanged, { type })
    },
    [trackEvent, onToggle, userOnly]
  )

  return (
    <ButtonGroup variant="outlined">
      <ToggleButton
        className={!example && !userOnly ? 'isSelected' : ''}
        onClick={() => handleToggle('organization')}
        disabled={!example && !userOnly}
        data-testid="questionClassificationToggle-Organization"
      >
        Organization
      </ToggleButton>
      <ToggleButton
        className={!example && userOnly ? 'isSelected' : ''}
        onClick={() => handleToggle('myContent')}
        disabled={!example && userOnly}
        data-testid="questionClassificationToggle-MyContent"
      >
        My Content
      </ToggleButton>
      <ToggleButton
        className={example ? 'isSelected' : ''}
        onClick={() => handleToggle('examples')}
        disabled={example}
        data-testid="questionClassificationToggle-Examples"
      >
        Examples
      </ToggleButton>
    </ButtonGroup>
  )
}
