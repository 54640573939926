import { Transcript as TranscriptIcon } from '@codinpad/shared-components/components/icons/index.js'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import MicOffIcon from '@mui/icons-material/MicOff'
import StopIcon from '@mui/icons-material/Stop'
import { Box, IconButton, styled, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { FooterButton } from '../../FooterButton'
import { enqueueNotif } from '../../reducers/notifications'
import { useTranscriberContext } from '../../Transcriber/TranscriberContext/TranscriberContext'
import { SoundIndicatorIcon } from './SoundIndicatorIcon'

const Container = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    border: `1px solid ${theme.palette.tokens.border.neutral2}`,
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  }
})

const MicOff = styled(MicOffIcon)(({ theme }) => ({
  color: theme.palette.tokens.icon.feedback.warning2,
}))
const Stop = styled(StopIcon)(({ theme }) => ({
  color: theme.palette.tokens.icon.feedback.error2,
}))

export function TranscriptionControls() {
  const dispatch = useDispatch()
  const { isOwner, hasPureTranscription } = usePadConfigValues('isOwner', 'hasPureTranscription')
  const {
    askForTranscriptionConsent,
    isTranscribing,
    isTranscriptionMuted,
    muteTranscription,
    setStopConfirmationStatus,
  } = useTranscriberContext()

  const hasNotified = useRef(false)
  useEffect(() => {
    if (!hasNotified.current && hasPureTranscription && isOwner) {
      dispatch(
        enqueueNotif({
          title: 'New Feature - Call Transcription',
          autoDismissMs: 15000,
          message:
            'Transcription will automatically start once 2 people join the pad. It works with your choice of video conference tool. Start, pause, and stop controls are at bottom left.',
          key: 'transcription_call_message',
          variant: 'info',
        })
      )
      hasNotified.current = true
    }
  }, [hasPureTranscription, dispatch, isOwner])

  return isTranscribing ? (
    <Container>
      <div>Transcription:</div>
      <Tooltip title="Mute your transcription" placement="top" arrow>
        <IconButton
          onClick={() => muteTranscription(!isTranscriptionMuted)}
          size="small"
          aria-label="mute transcription"
        >
          {isTranscriptionMuted ? <MicOff /> : <SoundIndicatorIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Stop your transcription" placement="top" arrow>
        <IconButton
          onClick={() => setStopConfirmationStatus(true)}
          size="small"
          aria-label="stop transcription"
        >
          {isTranscribing ? <Stop /> : <FiberManualRecordOutlinedIcon />}
        </IconButton>
      </Tooltip>
    </Container>
  ) : (
    <Tooltip
      title={
        <Typography textAlign="center">
          Prompt all pad participants to record interview transcript.
        </Typography>
      }
      placement="top"
      arrow
    >
      <span>
        <FooterButton
          size="small"
          variant="text"
          onClick={askForTranscriptionConsent}
          color="inherit"
          startIcon={<TranscriptIcon sx={{ height: 20, width: 20 }} />}
          aria-label="start transcription"
        >
          <Box sx={{ display: { xs: 'none', lg: 'initial' } }}>Start Transcription</Box>
        </FooterButton>
      </span>
    </Tooltip>
  )
}
