import { Box, Button, FormControl, InputLabel, Select, Typography } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'

import { useLanguages } from '../../../../../../graphql/hooks/languages/useLanguages'
import { useMakeUseExampleQuestion } from '../../../../../../graphql/hooks/question/useExampleQuestion/useExampleQuestion'
import * as queryStates from '../../../../../../graphql/queryStates'
import { Question } from '../../../../../../graphql/types'
import { useAnalyticsZone } from '../../../../../dashboard/components/AnalyticsZone/AnalyticsZone'
import { LanguageDisplay } from '../../../../../dashboard/components/LanguageDisplay/LanguageDisplay'
import { LoadingBlock } from '../../../../../dashboard/components/LoadingBlock/LoadingBlock'
import { PadAnalyticsEvent } from '../../../../constants'
import { DetailBody } from '../DetailBody/DetailBody'

interface IExampleDetailProps {
  exampleQuestionId?: string
  onSelect: (exampleId: string, envOpts: Record<string, any>) => void
}

export const ExampleDetail: React.FC<IExampleDetailProps> = ({ exampleQuestionId, onSelect }) => {
  const { languagesById } = useLanguages()
  const [selectedVariationId, setSelectedVariationId] = useState('')
  const { exampleQuestion: question, status } = useMakeUseExampleQuestion(`
    id
    title
    source
    description
    variations {
      id
      language
      projectTemplate {
        name
        slug
      }
      candidateInstructions {
        instructions
        defaultVisible
      }
      customDatabase {
        id
        language
        description
        title
      }
      customFiles {
        id
        title
        description
        filename
        filesize
      }
      testCasesEnabled
      testCases {
        returnValue
        visible
        arguments
      }
      solution
      contents
      fileContents
      questionVariants {
        id
        display
        language
        projectTemplate {
          id
          slug
          name
        }
        fileContents
        solution
      }
    }
  `)(exampleQuestionId)

  const selectedVariation = question?.variations.find((v) => v.id === selectedVariationId)
  const variationOptions =
    question?.variations.map((v) => ({
      label: languagesById[v.language!]?.name || v.language,
      value: v.id,
    })) || []

  // Change in question, select the first variation.
  useEffect(() => {
    if (question?.variations.length) {
      setSelectedVariationId(question?.variations[0].id)
    }
  }, [question?.id])

  const { trackEvent } = useAnalyticsZone()
  const handleSelection = useCallback(() => {
    if (question) {
      onSelect(question.id, {
        language: selectedVariation?.language,
      })
      trackEvent(PadAnalyticsEvent.QuestionBankAddToPad, {
        questionId: question.id,
        variation: selectedVariation?.language,
        isExample: true,
      })
    }
  }, [trackEvent, question, selectedVariation, onSelect])

  return (
    <LoadingBlock
      isLoading={queryStates.isLoadingState(status) && !question}
      message="Fetching question details"
      minDisplayMS={0}
    >
      {question && (
        <Box whiteSpace="pre-wrap" display="flex" flexDirection="column" height="100%">
          <Box mb={2} display="flex">
            <Typography variant="body1">{question.title}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary">
              {question.description}
            </Typography>
          </Box>
          {variationOptions.length > 1 && (
            <Box mt={2}>
              <FormControl variant="outlined" margin="dense">
                <InputLabel id="environment-question-menu-language-select-label">
                  Language
                </InputLabel>
                <Select
                  value={selectedVariationId}
                  labelId="environment-question-menu-example-variation-language-select-label"
                  aria-label="Question language"
                  id="environment-question-menu-example-variation-language-select"
                  onChange={(e) => setSelectedVariationId(e.target.value as string)}
                  label="Language"
                  native
                >
                  {variationOptions.map((opt) => {
                    return (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          )}
          {selectedVariation && (
            <Box mt={1.5}>
              <Typography variant="body2" color="textSecondary">
                <Box display="flex" alignItems="center" component="span">
                  <Box component="span" ml={1}>
                    {selectedVariation.questionVariants?.length > 0 ? (
                      <>
                        {selectedVariation.questionVariants.map((v) => (
                          <>
                            <LanguageDisplay
                              key={v.id}
                              display={v.projectTemplate?.name ?? languagesById[v.language!]?.name}
                              language={v.projectTemplate?.slug ?? v.language!}
                            />{' '}
                          </>
                        ))}{' '}
                      </>
                    ) : (
                      <>
                        <LanguageDisplay
                          display={
                            // TODO: support gsheets example questions
                            selectedVariation.projectTemplate?.name ??
                            languagesById[selectedVariation.language!]?.name
                          }
                          language={
                            selectedVariation.projectTemplate?.slug ?? selectedVariation.language!
                          }
                        />{' '}
                      </>
                    )}
                    by {question.source}{' '}
                    {question.createdAt &&
                      formatDistanceToNow(new Date(question.createdAt), { addSuffix: true })}
                  </Box>
                </Box>
              </Typography>
            </Box>
          )}
          <Box mt={2} flex={1} overflow="hidden">
            {/* There is enough overlap between a Question and an example variation to cast a variation to a Question */}
            {selectedVariation && (
              <DetailBody question={(selectedVariation as unknown) as Question} />
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button type="submit" color="primary" variant="contained" onClick={handleSelection}>
              Add Question
            </Button>
          </Box>
        </Box>
      )}
    </LoadingBlock>
  )
}
